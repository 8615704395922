@use 'sass:map';
@import '/src/assets/scss/shared';

.text_textarea {
  padding: 10px 16px;
  border-radius: 24px;
  background-color: $color-white;
  border: 1px solid map.get($color-list, 'neutral', 400);
  outline: none;
  resize: none;
  @include body-text(3);
  color: map.get($color-list, 'neutral', 800);

  // @include media-breakpoint-up("xxl") {
  //   height: 128px;
  // }

  &__wrapper {
    width: 100%;

    @include display-flex(column) {
      position: relative;
      margin-bottom: 24px;
    }
  }
  &__dis {
    display: flex;
    justify-content: space-between;
  }

  &::placeholder {
    color: map.get($color-list, 'neutral', 500);
  }

  &:active,
  &:focus {
    border-color: map.get($color-list, 'neutral', 800);
  }

  &:disabled {
    color: map.get($color-list, 'neutral', 400);
    border-color: map.get($color-list, 'neutral', 300);
  }

  &--error {
    border-color: map.get($color-list, 'red', 500);
  }

  &__label {
    @include subtitle-text(2);
    color: map.get($color-list, 'neutral', 800);
    text-indent: 8px;
  }

  &__required {
    color: map.get($color-list, 'green', 500);
  }

  &__optional {
    @include body-text(2) {
      color: map.get($color-list, 'neutral', 600);
    }
  }
}

.caption {
  position: absolute;
  width: 100%;
  text-indent: 8px;
  bottom: -20px;
  @include caption-text(2) {
    color: map.get($color-list, 'neutral', 500);
  }

  &__error {
    color: map.get($color-list, 'red', 500);
  }
}
