@use 'sass:map';
@import '/src/assets/scss/shared';

.page {
  // width: 920px;
  margin: 32px 40px;
}

.helpModal {
  margin-left: 1rem;
  margin-top: 0.2rem;
}

.search {
  &__wrapper {
    @include display-flex(column) {
      align-items: center;
      justify-content: center;
      background-color: map.get($color-list, 'orange', 100);
      border-radius: 24px;
      padding-top: 38px;
      padding-bottom: 44px;
      margin-bottom: 24px;
    }
  }

  &__title {
    @include subtitle-text {
      color: map.get($color-list, 'neutral', 800);
      margin-bottom: 26px;
      @include display-flex;
    }
  }

  &__form {
    @include display-flex {
      position: relative;
    }
  }

  &__icon {
    position: absolute;
    left: 16px;
    top: 8px;
  }

  &__input {
    width: 330px;
    padding-left: 56px;

    &__wrapper {
      margin-right: 8px;
    }
  }
}

.list {
  &__wrapper {
    @include display-flex(column);
  }

  &__header {
    position: relative;
    @include display-flex {
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
    }
  }

  &__results {
    @include body-text(2) {
      color: map.get($color-list, 'neutral', 500);
      text-align: center;
    }
  }

  &__view {
    @include display-flex(column) {
      border-radius: 12px;
    }

    &--empty {
      background-color: rgba(
        $color: map.get($color-list, 'neutral', 200),
        $alpha: 0.5
      );
      align-items: center;
      justify-content: center;
      padding: 72px;
      margin-top: 32px;
    }

    .person {
      @include display-flex {
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        color: map.get($color-list, 'neutral', 600);
        background-color: map.get($color-list, 'neutral', 200);
        border-radius: 12px;
        padding: 16px 56px 16px 24px;

        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }

      &:hover {
        background-color: $color-white;
        box-shadow: 0px 1px 8px -2px rgba(80, 85, 92, 0.03),
          0px 5px 10px 0.2px rgba(80, 85, 92, 0.03),
          0px 15px 20px 0.2px rgba(80, 85, 92, 0.05);
        color: map.get($color-list, 'neutral', 800);
      }

      &__name {
        @include subtitle-text {
          color: map.get($color-list, 'neutral', 800);
          width: 25%;
        }
      }

      &__type {
        text-transform: capitalize;
        color: map.get($color-list, 'green', 500);
        width: 15%;

        &--CUSTOMER {
        }

        &--AMBASSADOR {
        }

        &--COUNSELOR {
        }
      }

      &__email {
        width: 30%;
      }

      &__phone {
        width: 15%;
      }
    }
  }
}

.icon {
  &__filter {
    margin-right: 8px;
  }
}

.filter_button {
  width: 10rem;
}
