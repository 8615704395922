@use 'sass:map';
@import '/src/assets/scss/shared';

.modal {
  width: 685px;
  padding: 48px 118px 64px;

  * {
    transition-duration: unset;
  }

  &__content {
    @include display-flex(column) {
      height: 100%;
      align-items: center;
    }
  }
}

.close {
  position: absolute;
  background-color: transparent;
  padding: 0px;
  border: none;
  cursor: pointer;
  top: 16px;
  right: 16px;
}

.camp {
  &__logo {
    @include display-flex {
      position: relative;
      width: 80px;
      height: 80px;
      align-items: center;
      justify-content: center;
      background-color: map.get($color-list, 'green', 300);
      border-radius: 50%;
      overflow: hidden;
      margin-bottom: 40px;
    }

    img {
      width: 100%;
      height: 100%;
    }

    span {
      font-family: 'Rubik-Medium';
      font-weight: 500;
      font-size: 30px;
      line-height: 40px;
      color: map.get($color-list, 'green', 600);
    }
  }
}

.link {
  @include display-flex {
    align-items: center;
    padding: 16px;
    background-color: map.get($color-list, 'neutral', 200);
    border-radius: 12px;
    margin-bottom: 16px;
    width: 212px;
    transition-duration: 0.2s;
    cursor: pointer;
  }

  &:nth-child(2n) {
    margin-left: auto;
  }

  &:nth-child(2n + 1) {
    margin-right: auto;
  }

  &--promo-code {
    width: 100%;
    background-color: map.get($color-list, 'orange', 100);
  }

  &:hover {
    background-color: map.get($color-list, 'neutral', 100);
    @include box-shadow;
  }

  &s__wrapper {
    @include display-flex {
      flex-wrap: wrap;
      margin-top: 48px;
    }
  }

  span {
    margin-left: 16px;
    @include body-text(2);
    color: map.get($color-list, 'orange', 700);
  }
}

.text_center {
  text-align: center;
}
