@use "sass:map";
@use "sass:math";
@import "/src/assets/scss/shared";

.legend {
  @include display-flex {
    padding: 24px;
    flex-wrap: wrap;

    @include media-breakpoint-up("md") {
      padding-bottom: 40px;
    }

    @include media-breakpoint-up("xxl") {
      position: absolute;
      top: 24px;
      right: 140px;
      padding: 0px;
    }
  }

  &__symbol {
    @include display-flex {
      align-items: center;
      margin-right: 0px;
      margin-bottom: 16px;
      width: 50%;

      @include media-breakpoint-up("md") {
        margin-bottom: 0px;
        width: auto;

        &:not(:last-child) {
          margin-right: 40px;
        }
      }
    }

    &--green {
      background-color: map.get($color-list, "green", 400);
    }

    &--orange {
      background-color: map.get($color-list, "orange", 400);
    }

    &--blue {
      background-color: map.get($color-list, "blue", 400);
    }

    &--red {
      background-color: map.get($color-list, "red", 400);
    }

    span {
      width: 16px;
      height: 16px;
      margin-right: 16px;
      border-radius: 4px;
    }

    label {
      @include caption-text;
      color: map.get($color-list, "neutral", 800);

      @include media-breakpoint-up("xxl") {
        @include body-text;
      }
    }
  }
}

.page {
  padding: 0px 24px;

  @include media-breakpoint-up("xxl") {
    padding: 56px 140px;
  }
}

.stat_item {
  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &s__wrapper {
    @include display-flex(column);
  }
}

.button {
  &s__wrapper {
    @include display-flex {
      justify-content: space-between;
      margin-top: 128px;

      @include media-breakpoint-up("md") {
        justify-content: center;
      }
    }
  }

  &__back {
    @include media-breakpoint-up("md") {
      margin-right: 24px;
    }
  }

  &__export {
    svg {
      margin-right: 12px;
    }
  }

  &__back,
  &__export {
    width: 150px;

    @include media-breakpoint-up("md") {
      width: 140px;
    }
  }
}
