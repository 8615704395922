@use 'sass:map';
@import '/src/assets/scss/shared';

.modal {
  @include media-breakpoint-only('xs') {
    width: 100%;
    height: 100%;
    border-radius: 0px;
    padding: 164px 24px 24px;
  }

  @include media-breakpoint-up('xxl') {
    width: 800px;
    padding: 60px 176px 75px;
  }

  @include media-breakpoint-up('md') {
    width: 800px;
    max-height: 90vh;
  }

  @include media-breakpoint-up('xl') {
    width: 800px;
    padding: 50px 60px;
  }

  * {
    transition-duration: unset;
  }

  &__content {
    @include display-flex(column) {
      height: 100%;
    }
  }
}

.title {
  color: map.get($color-list, 'neutral', 800);
  margin-bottom: 40px;
  text-align: center;
}

.close {
  position: absolute;
  background-color: transparent;
  padding: 0px;
  border: none;
  cursor: pointer;
  top: 16px;
  right: 16px;
}

.form {
  @include display-flex(column) {
    flex-grow: 1;
  }

  &__row {
    @include display-flex;
  }

  &__col {
    @include display-flex(column) {
      flex-basis: 0;
      flex-grow: 1;

      &:not(:last-child) {
        margin-right: 24px;
      }
    }
  }

  &__buttons {
    @include display-flex {
      align-items: center;
      justify-content: center;
      margin-top: auto;
    }

    @include media-breakpoint-up('xxl') {
      margin-top: 40px;
    }
  }

  &__cancel {
    width: 150px;
    margin-right: 24px;

    @include media-breakpoint-up('xxl') {
      width: 110px;
    }
  }

  &__save {
    width: 150px;

    @include media-breakpoint-up('xxl') {
      width: 110px;
    }
  }
}

.new_ambassador {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 24px;
    margin-top: auto;
    button {
      width: 80%;
      height: 48px;
    }
}
.list {
  &__wrapper {
    @include display-flex(column);
  }

  &__header {
    position: relative;
    @include display-flex {
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
    }
  }

  &__results {
    @include body-text(2) {
      color: map.get($color-list, 'neutral', 500);
      text-align: center;
    }
  }

  &__view {
    @include display-flex(column) {
      border-radius: 12px;
      max-height: 250px;
      overflow: scroll;
      margin-bottom: 24px;
    }

    &--empty {
      background-color: rgba(
        $color: map.get($color-list, 'neutral', 200),
        $alpha: 0.5
      );
      align-items: center;
      justify-content: center;
      padding: 72px;
      margin-top: 32px;
    }

    .person {
      @include display-flex {
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        color: map.get($color-list, 'neutral', 600);
        background-color: map.get($color-list, 'neutral', 200);
        border-radius: 12px;
        padding: 16px 56px 16px 24px;

        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }

      &:hover {
        background-color: $color-white;
        box-shadow: 0px 1px 8px -2px rgba(80, 85, 92, 0.03),
          0px 5px 10px 0.2px rgba(80, 85, 92, 0.03),
          0px 15px 20px 0.2px rgba(80, 85, 92, 0.05);
        color: map.get($color-list, 'neutral', 800);
      }

      &__name {
        @include subtitle-text {
          color: map.get($color-list, 'neutral', 800);
          width: 25%;
        }
      }

      &__type {
        text-transform: capitalize;
        color: map.get($color-list, 'green', 500);
        width: 15%;

        &--CUSTOMER {
        }

        &--AMBASSADOR {
        }

        &--COUNSELOR {
        }
      }

      &__email {
        width: 30%;
      }

      &__phone {
        width: 15%;
      }
    }
  }
}
