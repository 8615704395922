@use 'sass:map';
@use 'sass:math';
@import '/src/assets/scss/shared';

.hide {
  display: none;
}

.camp__logo {
  @include display-flex {
    align-items: center;
    justify-content: center;
    margin: 20px;
    width: 64px;
    height: 64px;
    background-color: map.get($color-list, 'green', 300);
    border-radius: 50%;
    overflow: hidden;

    svg, img {
      width: 100%;
      height: 100%;
    }
  }
}

.top_bar {
  @include display-flex {
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 16px 24px;
    margin: auto;

    @include media-breakpoint-up('lg') {
      width: map.get($device-size, 'lg');
    }

    @include media-breakpoint-up('xl') {
      width: map.get($device-size, 'xl');
    }

    @include media-breakpoint-up('xxl') {
      width: map.get($device-size, 'xxl');
    }
  }

  &__logo {
    @include display-flex {
      position: absolute;
      left: 24px;
    }
  }
  &__name {
    line-height: 5.4rem;
    margin: auto 0 auto 10px;
  }

  &__menu {
    @include display-flex;

    &__item {
      @include display-flex {
        height: 48px;
        align-items: center;
        margin-left: 12px;
        margin-right: 12px;
      }

      @include button-text {
        text-decoration: none;
        color: map.get($color-list, 'neutral', 500);
        text-transform: uppercase;

        &:hover,
        &__active {
          color: map.get($color-list, 'green', 500);
        }
      }
    }
  }

  &__wrapper {
    background-color: map.get($color-list, 'neutral', 200);
    border-bottom: 1px solid map.get($color-list, 'neutral', 300);
  }
}

.badge {
  @include display-flex {
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -6px;
    bottom: 0px;
    width: 20px;
    height: 16px;
    border-radius: 8px;
    background-color: map.get($color-list, 'orange', 500);
  }

  @include tag-text {
    color: map.get($color-list, 'neutral', 100);
  }
}

.user {
  @include display-flex {
    position: absolute;
    right: 24px;
    align-items: center;
    cursor: pointer;
  }

  &__avatar {
    font-size: 5px;
    @include display-flex {
      position: relative;
      width: 40px;
      height: 40px;
      align-items: center;
      justify-content: center;
      background-color: map.get($color-list, 'green', 300);
      border-radius: 50%;
    }

    @include subtitle-text {
      color: map.get($color-list, 'green', 600);
      font-size: 1.2rem;
    }
  }

  &__name {
    @include subtitle-text(2);
    color: map.get($color-list, 'neutral', 800);
    margin-left: 16px;
    margin-right: 16px;
  }

  &__arrow {
    &__down {
      transform: rotate(180deg);
    }
  }

  &__menu {
    position: absolute;
    padding: 0px 16px;
    width: 210px;
    right: 24px;
    top: 73px;
    background-color: #fff;
    border-radius: 24px;
    @include box-shadow;
    max-height: 0px;
    overflow: hidden;
    z-index: 10;

    &__opened {
      padding: 16px;
      max-height: 190px;
    }

    &__item {
      cursor: pointer;
      @include display-flex {
        position: relative;
        align-items: center;
        padding: 8px 0px;
        text-decoration: none;
      }

      &__icon {
        margin-right: 16px;
      }

      &__text {
        @include subtitle-text;
        color: map.get($color-list, 'neutral', 800);
      }

      &__badge {
        top: 12px;
        right: 0px;
      }

      &__logout {
        cursor: pointer;
        border-color: transparent;
        background-color: transparent;
        width: 100%;

        &_text {
          color: map.get($color-list, 'red', 500);
        }
      }
    }
  }
}
