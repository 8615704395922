@use 'sass:map';
@use 'sass:math';
@import '/src/assets/scss/shared';

.section {
  border: none;
  background-color: #fff;
  border-radius: 2.4rem;
  margin-bottom: 2.4rem;

  .profile_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .helpModal {
    margin-left: auto;
    margin-right: 20px;
    &__types {
      margin-left: auto;
      margin-bottom: auto;
    }
    &__buttons {
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  &__title {
    color: map.get($color-list, 'green', 500);
    padding: 34px 50px 34px;
  }

  &__description {
    color: #333333;
    padding: 0px 50px 10px;
    display: flex;
    div {
      width: 90px;
    }
  }
  &__footer {
    @include display-flex {
      align-items: center;
      justify-content: center;
      height: 100px;
    }
  }
}

.form {
  &__wrapper {
    background-color: map.get($color-list, 'neutral', 100);
    border-radius: 24px;
    overflow: hidden;
    margin-bottom: 24px;
  }

  &__sample_csv {
    color: map.get($color-list, 'orange', 600);
  }

  &__header {
    @include display-flex {
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
      border: 2px solid #fdca7d;
      border-radius: 26px;
      margin: 0 20px 0;
    }

    &__secondary {
      display: flex;
      flex-wrap: wrap;
      margin: 20px 20px 0;
      column-gap: 5px;
    }
    &__center {
      width: 100%;
      text-align: center;
      display: flex;
    }
    &__content {
      display: flex;
      margin-top: 20px;
      &__title {
        font-family: 'Rubik-Medium';
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 2.4rem;
        letter-spacing: 0.25px;
        color: #333333;
        min-width: 200px;
      }
      p {
        font-size: 1.4rem;
      }
    }
    &__title {
      color: map.get($color-list, 'neutral', 800);
      width: 100%;
      text-align: center;
      font-family: 'Rubik-Medium';
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 2.4rem;
      letter-spacing: 0.25px;
      color: #333333;
    }

    &__title__center {
      color: map.get($color-list, 'neutral', 800);
    }
    &__desc {
      @include body-text(3);
      color: map.get($color-list, 'neutral', 600);
      display: inline-block;
    }

    &__buttons {
      @include display-flex {
        margin-top: 40px;
        width: 100%;
      }
    }
  }

  &__green__header__first {
    @include display-flex {
      flex: 65% 0;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 10px 20px;
      border: 2px solid #56a748;
      border-radius: 26px;
      &__title {
        display: flex;
        width: 100%;
        justify-content: start;
      }
    }

    &__actions {
      @include display-flex {
        width: 100%;
        flex-wrap: wrap;
        column-gap: 10px;
      }
    }
  }

  &__green__header {
    @include display-flex {
      flex: 34% 0;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 10px 20px;
      border: 2px solid #56a748;
      border-radius: 26px;
      row-gap: 10px;
    }

    &__actions {
      @include display-flex {
        width: 100%;
        flex-wrap: wrap;
        column-gap: 10px;
        row-gap: 10px;
      }
      &__desc {
        font-size: 1.4rem;
        text-align: center;
        font-style: italic;
      }
    }
  }

  &__main {
    padding: 40px 20px 50px;

    &__desc {
      @include body-text(3);
      color: map.get($color-list, 'neutral', 400);
      text-align: center;
      margin-top: 24px;
    }
  }

  &__row {
    @include display-flex {
      justify-content: space-between;
    }
  }

  &__col {
    @include display-flex(column) {
      flex-basis: 0;
      flex-grow: 1;

      &:not(:last-child) {
        margin-right: 24px;
      }
    }
  }
}

.button {
  &__icon {
    margin-right: 8px;

    &--disabled * {
      fill: map.get($color-list, 'neutral', 200);
      stroke: map.get($color-list, 'neutral', 400);
    }
  }

  &__send {
    margin-right: auto;

    &__wrapper {
      @include display-flex;
    }

    &__container {
      @include display-flex(column);

      label {
        visibility: hidden;
        @include subtitle-text(2);
        color: map.get($color-list, 'neutral', 800);
        text-indent: 8px;
      }
    }
  }

  &__upload_csv,
  &__add_manually {
    flex: 40%;
    width: 90%;
  }

  &__back {
    margin-right: 16px;
  }

  &__launch {
    background-color: map.get($color-list, 'green', 300);
    color: map.get($color-list, 'green', 600);
    margin-right: 16px;

    &:hover {
      background-color: map.get($color-list, 'green', 200);
    }

    &:active,
    &:focus {
      background-color: map.get($color-list, 'green', 400);
    }
  }
}

.modal__add_ambassador_manually {
  overflow: auto;
}

.ambassador_list {
  width: 100%;

  &__header {
    @include display-flex {
      align-items: center;
      margin-bottom: 32px;
    }

    &__text {
      @include subtitle-text;
      color: map.get($color-list, 'green', 500);
      margin-right: auto;
    }
  }

  &__button {
    &__select_all {
      color: map.get($color-list, 'orange', 700);
    }

    &__delete_all {
      color: map.get($color-list, 'red', 500);
    }
  }

  &__th {
    @include body-text(2) {
      // border-bottom: 1px solid map.get($color-list, 'neutral', 300);
      text-align: left;
      color: map.get($color-list, 'neutral', 500);
      // padding-bottom: 8px;
    }

    &__status {
      width: 135px;
    }

    &__status_date {
      width: 170px;
    }

    &__actions {
      width: 75px;
    }
  }

  & > tbody tr:first-child td {
    padding-top: 16px;
  }

  &__td {
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid map.get($color-list, 'neutral', 300);
    @include body-text(3);

    &__actions {
      button {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &:first-child {
          margin-right: 24px;
        }
      }
    }
  }
}

.ambassador {
  &__status {
    padding: 4px 8px;
    border-radius: 12px;

    @include caption-text {
      text-transform: capitalize;
    }

    &--PENDING {
      background-color: map.get($color-list, 'orange', 200);
      color: map.get($color-list, 'orange', 700);
    }
  }
}

.amb_filter {
  &__wrapper {
    overflow: visible;
    position: relative;
  }
  &__filtered_button {
    background-color: map.get($color-list, 'orange', 300);
  }
  &.filter_text {
    color: map.get($color-list, 'orange', 700) !important;
  }

  &__icon {
    margin-left: 0.8rem;
    transform: rotate(180deg);

    * {
      stroke: map.get($color-list, 'orange', 700);
    }

    &--up {
      transform: rotate(0deg);
    }
  }

  &__menu {
    @include display-flex(column) {
      position: absolute;
      z-index: 10;
      width: 12rem;
      max-height: 0rem;
      overflow: scroll;
      background-color: $color-white;
      border-radius: 2.4rem;
      box-shadow: 0px 1px 8px -2px rgba(80, 85, 92, 0.03),
        0px 5px 10px 0.2px rgba(80, 85, 92, 0.03),
        0px 15px 20px 0.2px rgba(80, 85, 92, 0.05);
    }

    &--opened {
      max-height: 30rem;
    }

    &__item {
      @include body-text {
        padding: 0.8rem 2.4rem;
        text-align: right;
        color: map.get($color-list, 'orange', 700);
        text-align: center;
      }

      &:hover {
        background-color: map.get($color-list, 'orange', 200);
      }
    }

    &__item_active {
      @include body-text {
        padding: 0.8rem 2.4rem;
        text-align: right;
        color: map.get($color-list, 'orange', 700);
        text-align: center;
        background-color: map.get($color-list, 'orange', 200);
      }

      &:hover {
        background-color: map.get($color-list, 'orange', 200);
      }
    }
  }
}

.search {
  &__wrapper {
    @include display-flex(column) {
      align-items: center;
      justify-content: center;
      background-color: map.get($color-list, 'orange', 100);
      border-radius: 24px;
      padding-top: 38px;
      padding-bottom: 44px;
      margin-bottom: 24px;
    }
  }

  &__title {
    @include subtitle-text {
      color: map.get($color-list, 'neutral', 800);
      margin-bottom: 26px;
    }
  }

  &__form {
    @include display-flex {
      position: relative;
    }
  }

  &__icon {
    position: absolute;
    left: 16px;
    top: 8px;
  }

  &__input {
    width: 200px;
    padding-left: 20px;
    margin-right: 5px;

    &__wrapper {
      margin-bottom: 0px;
      flex-direction: row;
      justify-content: flex-end;
      width: auto;
    }
  }
}
