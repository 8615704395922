@use 'sass:map';
@import '/src/assets/scss/shared';

.page {
  padding: 3.2rem 2.4rem;

  &__top {
    @include display-flex(column) {
      @include media-breakpoint-up('md') {
        flex-direction: row;
      }
    }

    &__search {
      @include media-breakpoint-up('md') {
        max-width: 33rem;
        margin-left: 1.6rem;
      }
    }
  }

  &__content {
    @include display-flex {
      justify-content: space-between;
      overflow: auto;
      width: calc(300vw - 10rem);

      @include media-breakpoint-up('md') {
        width: 165rem;
      }

      @include media-breakpoint-up('xxl') {
        width: 100%;
      }
    }

    &__wrapper {
      overflow-x: auto;
      margin-bottom: 2.4rem;
    }
  }

  &__bottom {
    @include display-flex {
      justify-content: space-between;
      width: calc(100vw - 4.8rem);
      max-width: 40rem;
    }

    &__wrapper {
      @include display-flex {
        justify-content: center;
      }
    }
  }
}
.help {
  margin-left: 10px;
}

.create_campaign {
  &__wrapper {
    position: relative;
    margin-bottom: 1.6rem;

    @include media-breakpoint-up('md') {
      margin-right: auto;
      margin-bottom: 2.4rem;
    }

    &__help {
      @include display-flex {
        align-items: center;
      }
    }
  }

  &__icon {
    margin-left: 0.8rem;
    transform: rotate(180deg);

    * {
      stroke: map.get($color-list, 'orange', 700);
    }

    &--up {
      transform: rotate(0deg);
    }
  }

  &__menu {
    @include display-flex(column) {
      position: absolute;
      left: 0rem;
      top: 4.8rem;
      right: 0rem;
      z-index: 10;
      width: 100%;
      max-height: 0rem;
      overflow: hidden;
      background-color: $color-white;
      border-radius: 2.4rem;
      box-shadow: 0px 1px 8px -2px rgba(80, 85, 92, 0.03),
        0px 5px 10px 0.2px rgba(80, 85, 92, 0.03),
        0px 15px 20px 0.2px rgba(80, 85, 92, 0.05);
    }

    &--opened {
      max-height: 15rem;
    }

    &__item {
      @include body-text {
        padding: 0.8rem 0.16rem;
        text-align: right;
        color: map.get($color-list, 'orange', 700);
      }

      &:hover {
        background-color: map.get($color-list, 'orange', 200);
      }
    }
  }
}

.button {
  &__status {
    @include display-flex {
      align-items: center;
      justify-content: center;
      background-color: map.get($color-list, 'neutral', 100);
      border: 1px solid map.get($color-list, 'neutral,300');
      border-radius: 3rem;
      text-transform: capitalize;
      width: 10rem;
      height: 3.2rem;
      @include caption-text;
      color: map.get($color-list, 'neutral', 600);

      @include media-breakpoint-up('md') {
        width: 12.4rem;
        height: 4rem;
        @include subtitle-text(3);
      }
    }

    &--selected {
      background-color: map.get($color-list, 'orange', 400);
      border-color: map.get($color-list, 'orange', 400);
      color: map.get($color-list, 'orange', 700);
    }
  }
}

.section {
  @include display-flex(column) {
    border-radius: 2.4rem;
    overflow: hidden;
  }

  &__header {
    padding: 2rem 2.4rem;
    text-transform: capitalize;
    font-family: 'Rubik-Medium';
    font-size: 1.8rem;
    font-weight: 500;

    &--INCOMPLETE {
      background-color: map.get($color-list, 'orange', 100);

      span:first-child {
        color: map.get($color-list, 'orange', 700);
      }

      span:last-child {
        color: map.get($color-list, 'orange', 400);
      }
    }

    &--ACTIVE {
      background-color: map.get($color-list, 'green', 100);

      span:first-child {
        color: map.get($color-list, 'green', 500);
      }

      span:last-child {
        color: map.get($color-list, 'green', 300);
      }
    }

    &--CLOSED {
      background-color: map.get($color-list, 'red', 100);

      span:first-child {
        color: map.get($color-list, 'red', 500);
      }
      span:nth-child(2) {
        color: map.get($color-list, 'red', 300);
      }
      span:nth-last-child(2) {
        color: map.get($color-list, 'neutral', 500);
        margin-right: 1rem;
      }
      span:last-child {
        color: map.get($color-list, 'neutral', 500);
      }
    }

    &--ARCHIVED {
      background-color: map.get($color-list, 'neutral', 200);

      span:first-child {
        color: map.get($color-list, 'neutral', 500);
      }

      span:nth-child(2) {
        color: map.get($color-list, 'neutral', 500);
      }
    }

    span:first-child {
      margin-right: 0.8rem;
    }
    span:nth-last-child(2) {
      margin-right: 0.8rem;
      margin-left: 0.8rem;
    }
  }

  &__content {
    @include display-flex(column) {
      transition-duration: unset;
      width: calc(100vw - 4.8rem);
      height: 38.4rem;

      @include media-breakpoint-up('md') {
        width: 53.4rem;
        height: 63.2rem;
      }

      @include media-breakpoint-up('xxl') {
        width: 44.8rem;
        height: 57.6rem;
      }
    }

    &--INCOMPLETE {
      background-color: map.get($color-list, 'orange', 100);
    }

    &--ACTIVE {
      background-color: map.get($color-list, 'green', 100);
    }

    &--CLOSED {
      background-color: map.get($color-list, 'red', 100);
    }

    &--ARCHIVED {
      background-color: map.get($color-list, 'neutral', 200);
    }

    &__no_items {
      @include display-flex(column) {
        align-items: center;
        padding-top: 8rem;
        background-color: map.get($color-list, 'neutral', 200);

        @include media-breakpoint-up('md') {
          padding-top: 15rem;
        }

        @include media-breakpoint-up('xxl') {
          padding-top: 17rem;
        }
      }

      &--INCOMPLETE {
        color: map.get($color-list, 'orange', 400);
        background-color: map.get($color-list, 'orange', 100);

        svg {
          path:first-child {
            fill: map.get($color-list, 'orange', 200);
            stroke: map.get($color-list, 'orange', 500);
          }

          path:last-child {
            fill: map.get($color-list, 'orange', 400);
            stroke: map.get($color-list, 'orange', 500);
          }
        }
      }

      &--ACTIVE {
        color: map.get($color-list, 'green', 400);
        background-color: map.get($color-list, 'green', 100);

        svg {
          path:first-child {
            fill: map.get($color-list, 'green', 200);
            stroke: map.get($color-list, 'green', 500);
          }

          path:last-child {
            fill: map.get($color-list, 'green', 400);
            stroke: map.get($color-list, 'green', 500);
          }
        }
      }

      &--CLOSED {
        color: map.get($color-list, 'red', 400);
        background-color: map.get($color-list, 'red', 100);

        svg {
          path:first-child {
            fill: map.get($color-list, 'red', 200);
            stroke: map.get($color-list, 'red', 500);
          }

          path:last-child {
            fill: map.get($color-list, 'red', 400);
            stroke: map.get($color-list, 'red', 500);
          }
        }
      }

      &--ARCHIVED {
        color: map.get($color-list, 'neutral', 500);
        background-color: map.get($color-list, 'neutral', 200);

        svg {
          path:first-child {
            fill: map.get($color-list, 'neutral', 500);
            stroke: map.get($color-list, 'neutral', 500);
          }

          path:last-child {
            fill: map.get($color-list, 'neutral', 400);
            stroke: map.get($color-list, 'neutral', 500);
          }
        }
      }

      svg {
        margin-bottom: 2.4rem;
      }

      p {
        @include body-text(3);

        span {
          text-transform: capitalize;
        }
      }
    }

    &__inner {
      flex-basis: 0;
      flex-grow: 1;
      max-height: 100%;
      margin-right: 0.6rem;
      padding: 1.6rem;
      overflow-y: auto;

      @include media-breakpoint-up('md') {
        padding-left: 2.4rem;
        padding-right: 2.4rem;
      }

      &::-webkit-scrollbar {
        width: 0.6rem;

        &-thumb {
          border-radius: 3px;
        }
      }

      &--INCOMPLETE {
        &::-webkit-scrollbar-thumb {
          background-color: map.get($color-list, 'orange', 200);
        }
      }

      &--ACTIVE {
        &::-webkit-scrollbar-thumb {
          background-color: map.get($color-list, 'green', 200);
        }
      }

      &--CLOSED {
        &::-webkit-scrollbar-thumb {
          background-color: map.get($color-list, 'red', 200);
        }
      }
    }
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 0.8rem;
    }
  }
}
