@use "sass:map";
@import "/src/assets/scss/shared";

.modal {
  @include media-breakpoint-down("sm") {
    width: 100%;
    height: 100%;
    border-radius: 0px;
    padding: 164px 24px 24px;
  }
}

.title {
  color: map.get($color-list, "neutral", 800);
  margin-bottom: 64px;
  text-align: center;
}

.close {
  position: absolute;
  background-color: transparent;
  padding: 0px;
  border: none;
  cursor: pointer;
  top: 16px;
  right: 16px;
}

.forgot_password {
  text-align: center;
  margin-top: 16px;
  margin-bottom: 128px;

  a {
    @include body-hyperlink-text;
    color: map.get($color-list, "green", 500);
  }

  @include media-breakpoint-up("xxl") {
    margin-bottom: 40px;
  }
}

.form {
  &__buttons {
    @include display-flex {
      align-items: center;
      justify-content: center;
    }
  }

  &__cancel {
    width: 150px;
    margin-right: 24px;

    @include media-breakpoint-up("xxl") {
      width: 110px;
    }
  }

  &__save {
    width: 150px;

    @include media-breakpoint-up("xxl") {
      width: 110px;
    }
  }
}
