@use 'sass:map';
@import '/src/assets/scss/shared';

.form__sample_csv {
  color: map.get($color-list, 'orange', 600);
  margin-right: 5px;
}
.form__header__desc {
  @include body-text(3);
  color: map.get($color-list, 'neutral', 600);
  display: flex;
}

.help_icon {
  cursor: pointer;
}

// modal

.modal {
  @include media-breakpoint-down('sm') {
    width: 100%;
    height: 100%;
    border-radius: 0px;
    padding: 164px 24px 24px;
  }
}

.title {
  color: map.get($color-list, 'orange', 700);
  margin-bottom: 16px;
  text-align: center;
}

.body {
  color: map.get($color-list, 'neutral', 800);
  margin-bottom: 32px;
  text-align: left;
}

.close {
  position: absolute;
  background-color: transparent;
  padding: 0px;
  border: none;
  cursor: pointer;
  top: 16px;
  right: 16px;
}

.buttons {
  @include display-flex {
    align-items: center;
    justify-content: center;
  }

  .button {
    &__cancel {
      width: 150px;
      margin-right: 24px;

      @include media-breakpoint-up('xxl') {
        width: 110px;
      }
    }
  }
}
