@use 'sass:map';
@use 'sass:math';
@import '/src/assets/scss/shared';

.page {
  @include display-flex(column) {
    background-color: $color-white;
    min-height: 100vh;
    padding-left: 24px;
    padding-right: 24px;
  }

  @include media-breakpoint-up('md') {
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 56px;
  }

  @include media-breakpoint-up('xxl') {
    width: 560px;
    min-height: 748px;
    padding: 50px 100px;
    border-radius: 24px;
    margin: unset;
  }
  &__payment {
    @include media-breakpoint-up('md') {
      width: 600px;
    }

    @include media-breakpoint-up('xxl') {
      padding: 50px 20px;
    }
  }

  &__title {
    text-align: center;
    color: map.get($color-list, 'green', 500);
    margin-bottom: 32px;
    @include media-breakpoint-up('xxl') {
      text-align: left;
    }
  }

  &__container {
    @include media-breakpoint-up('xxl') {
      @include display-flex {
        min-height: 900px;
        background-color: map.get($color-list, 'neutral', 200);
      }
    }
  }

  &__wrapper {
    @include media-breakpoint-up('xxl') {
      @include display-flex {
        width: map.get($device-size, 'xxl');
        padding: 64px 140px 72px;
        margin: auto;
      }
    }
  }

  &__illustration {
    @include media-breakpoint-up('xxl') {
      margin: 196px auto 140px 80px;
    }
  }
}

.logo {
  margin: 48px auto 24px;
  width: 110px;
  height: 56px;

  @include media-breakpoint-up('xxl') {
    margin-left: 0px;
    margin-top: 0px;
  }
}

.error {
  font-size: 1.4rem;
  font-weight: 700;
  text-align: center;
  color: map.get($color-list, 'orange', 500);
  margin: 0;
}

.signup_with {
  @include subtitle-text(2) {
    color: map.get($color-list, 'neutral', 500);
  }

  margin-bottom: 16px;
}

.social_button {
  @include display-flex {
    align-items: center;
    padding: 16px 24px;
    width: 150px;
    background-color: map.get($color-list, 'neutral', 200);
    border-radius: 12px;
    border: none;
  }

  &s__container {
    @include display-flex {
      justify-content: space-between;
      margin-bottom: 56px;
    }
  }

  span {
    @include body-text(2);
    color: map.get($color-list, 'neutral', 800);
    margin-left: 16px;
  }

  @include media-breakpoint-up('xxl') {
    width: 155px;

    &s__container {
      margin-bottom: 40px;
    }
  }
}

.confirm_password {
  margin-bottom: 3.2rem;
}

.button {
  &__login {
    margin-bottom: 16px;
  }

  &__sign_up {
    margin-bottom: 20px;
  }

  &s__container {
    margin-top: 3.2rem;
    display: flex;
    justify-content: center;
  }

  @include media-breakpoint-up('md') {
    &s__container {
      @include display-flex {
        justify-content: center;
      }
    }

    &__sign_up,
    &__login {
      width: 160px;
      margin-bottom: 0;

      @include media-breakpoint-up('xxl') {
        width: 150px;
      }
    }
  }
}

.payment_info {
  margin-bottom: 30px;
}
