@use "sass:map";
@use "sass:math";
@import "/src/assets/scss/shared";

.select {
  padding: 10px 16px;
  border-radius: 24px;
  background-color: $color-white;
  border: 1px solid map.get($color-list, "neutral", 400);
  outline: none;
  height: 48px;
  cursor: pointer;
  transition-duration: math.div($transition-duration, 4);
  @include body-text(3);
  color: map.get($color-list, "neutral", 800);

  @include media-breakpoint-up("xxl") {
    height: 40px;
  }

  &__wrapper {
    @include display-flex(column) {
      position: relative;
      width: 100%;
      margin-bottom: 24px;
    }
    &__dis {
      display: flex;
      justify-content: space-between;
    }
  }

  &:hover {
    border-color: map.get($color-list, "neutral", 800);
  }

  &--opened {
    border-color: map.get($color-list, "neutral", 800);
    border-radius: 20px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-color: transparent;

    &:hover {
      border-bottom-color: transparent;
    }
  }

  &--disabled {
    cursor: unset;
    color: map.get($color-list, "neutral", 400);

    &,
    &:hover {
      border-color: map.get($color-list, "neutral", 300);
    }
  }

  &--error {
    border-color: map.get($color-list, "red", 500);
  }

  &__label {
    @include subtitle-text(2);
    color: map.get($color-list, "neutral", 800);
    text-indent: 8px;
  }

  &__required {
    color: map.get($color-list, "green", 500);
  }

  &__optional {
    @include body-text(2) {
      color: map.get($color-list, "neutral", 600);
    }
  }

  &__value {
    @include display-flex {
      align-items: center;
      justify-content: space-between;
    }
  }

  &__placeholder {
    color: map.get($color-list, "neutral", 500);
  }

  &__arrow {
    transform: rotate(180deg);

    @include media-breakpoint-up("xxl") {
      width: 20px;
      height: 20px;
    }

    &--disabled * {
      stroke: map.get($color-list, "neutral", 400);
    }

    &--opened {
      transform: rotate(0deg);
    }
  }

  &__option {
    @include body-text(2);
    padding-left: 16px;
    padding-right: 16px;
    cursor: pointer;

    &:first-child {
      margin-top: 10px;
    }

    &:last-child {
      margin-bottom: 10px;
    }

    &--selected {
      background-color: map.get($color-list, "neutral", 300);
    }

    &s__wrapper {
      position: absolute;
      z-index: 10;
      background-color: map.get($color-list, "neutral", 100);
      width: 100%;
      left: 0;
      // top: 71px;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      max-height: 0px;
      overflow: hidden;
      transition-duration: math.div($transition-duration, 2);

      @include media-breakpoint-up("xxl") {
        // top: 63px;
      }

      &--opened {
        border: 1px solid map.get($color-list, "neutral", 800);
        border-top: none;
      }
    }
  }
}

.caption {
  position: absolute;
  width: 100%;
  text-indent: 8px;
  bottom: -20px;
  @include caption-text(2) {
    color: map.get($color-list, "neutral", 500);
  }

  &__error {
    color: map.get($color-list, "red", 500);
  }
}
