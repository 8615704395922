@use 'sass:map';
@import 'texts';

/*
 * A place to put figma text styles
 */

.headline-4 {
  @include headline-text(4);
}

.headline-6-neutral {
  @include headline-text(6);
  color: map.get($color-list, 'neutral', 800);
}

.headline-6-green {
  @include headline-text(6);
  color: map.get($color-list, 'green', 500);
}

.subtitle-1-orange {
  @include subtitle-text(1) {
    color: map.get($color-list, 'orange', 600);
  }
}

.subtitle-1-green {
  @include subtitle-text(1) {
    color: map.get($color-list, 'green', 500);
  }
}

.subtitle-2-orange {
  @include subtitle-text(2) {
    color: map.get($color-list, 'orange', 600);
  }
}

.subtitle-2-green {
  @include subtitle-text(2) {
    color: map.get($color-list, 'green', 500);
  }
}

.subtitle-2-orange {
  @include subtitle-text(2) {
    color: map.get($color-list, 'orange', 600);
  }
}

.subtitle-2-green {
  @include subtitle-text(2) {
    color: map.get($color-list, 'green', 500);
  }
}

.caption-1-neutral600{
  @include caption-text(1) {
    color: map.get($color-list, 'neutral', 600);
  }
}

.caption-1-hyperlink-orange {
  @include caption-hyperlink-text(1) {
    color: map.get($color-list, 'orange', 700);
  }
  cursor: pointer;
}

.caption-1-hyperlink-green {
  @include caption-hyperlink-text(1) {
    color: map.get($color-list, 'green', 700);
  }
  cursor: pointer;
}

.hyperlink {
  text-decoration: underline;
  cursor: pointer;
}
