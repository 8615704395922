@use 'sass:map';
@import '/src/assets/scss/shared';

.section {
  @include display-flex(column) {
    justify-content: space-between;
    height: 100%;
  }

  &__title {
    color: map.get($color-list, 'green', 500);
    padding: 34px 52px 34px 8px;
  }

  .profile_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .helpModal {
    margin-top: 0.2rem;

    &__metrics {
      margin-left: 1rem;
      margin-top: 0.2rem;
    }
  }

  &__header {
    @include display-flex;
    margin-bottom: 2.4rem;
  }

  &__main {
    @include display-flex(column) {
      background-color: $color-white;
      border-radius: 2.4rem;
      padding: 2.4rem;
    }
  }

  &__footer {
    @include display-flex {
      align-items: center;
      justify-content: center;
      height: 10rem;
      padding: 2.4rem 0px 3.6rem;
      margin-top: auto;
    }
  }
}

.block {
  width: 100%;
  height: 100%;
  background-color: $color-white;
  border-radius: 24px;
  padding: 24px;
  position: relative;
  padding-bottom: 34px;
  overflow: hidden;

  &::after {
    position: absolute;
    content: '';
    width: 158px;
    height: 158px;
    border-radius: 50%;
    bottom: -79px;
    right: -86px;
    z-index: auto;
  }

  &--green::after {
    background-color: map.get($color-list, 'green', 100);
  }

  &--red::after {
    background-color: map.get($color-list, 'red', 100);
  }

  &--blue::after {
    background-color: map.get($color-list, 'blue', 100);
  }
  &--blue {
    padding: 24px 12px;
  }

  &__title {
    @include subtitle-text;
    margin-bottom: 16px;
    @include display-flex;
    justify-content: space-between;
    &--green {
      color: map.get($color-list, 'green', 500);
    }

    &--red {
      color: map.get($color-list, 'red', 400);
    }

    &--blue {
      color: map.get($color-list, 'blue', 400);
    }
  }

  h6 {
    color: map.get($color-list, 'neutral', 800);
  }
}

.ambassador_landing_page {
  width: 35rem;
  margin-right: 1.2rem;

  &__row {
    @include display-flex {
      width: 100%;
      align-items: top;
      justify-content: space-between;
      column-gap: 3rem;
    }
  }

  &__col {
    flex-basis: 0;
    flex-grow: 1;
  }

  &__label {
    @include body-text(2) {
      color: map.get($color-list, 'neutral', 500);
      margin-bottom: 8px;
    }
    @include display-flex;
    justify-content: space-between;
  }
}

.prospect_landing_page {
  width: 35rem;
  margin-right: 1.4rem;

  &__row {
    @include display-flex {
      width: 100%;
      align-items: top;
      justify-content: space-between;
      column-gap: 1rem;
    }
  }

  &__col {
    flex-basis: 0;
    flex-grow: 1;
  }

  &__label {
    @include body-text(2) {
      color: map.get($color-list, 'neutral', 500);
      margin-bottom: 8px;
    }
    @include display-flex {
      justify-content: space-between;
    }
    height: 5rem;
  }
}

.referrals {
  width: 15rem;
  margin-right: 1.2rem;
}

.registrations {
  width: 15rem;
}

.metrics {
  &__header {
    @include display-flex {
      align-items: center;
      justify-content: space-between;
      margin-bottom: 3.2rem;
    }
  }

  &__title {
    @include subtitle-text;
    color: map.get($color-list, 'neutral', 800);
    @include display-flex;
  }

  &__th {
    border-bottom: 1px solid map.get($color-list, 'neutral', 300);
    padding-bottom: 0.8rem;
    width: 12%;

    @include body-text(2) {
      text-align: left;
      color: map.get($color-list, 'neutral', 500);
    }

    &__event {
      width: 20%;
    }
  }

  &__tr:first-child td {
    padding-top: 1.6rem;
  }

  &__td {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    border-bottom: 1px solid map.get($color-list, 'neutral', 300);
    @include body-text(3);

    &__total {
      @include subtitle-text(2) {
        color: map.get($color-list, 'orange', 700);
      }
    }
  }
}

.button {
  &__download {
    &__icon {
      margin-right: 0.8rem;
    }
  }

  &__next {
    margin-right: 0.8rem;
  }
}
