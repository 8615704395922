@use 'sass:map';
@use 'sass:math';
@import '/src/assets/scss/shared';

.page {
  padding: 24px;

  @include media-breakpoint-up('md') {
    padding-top: 50px;
    padding-bottom: 50px;
    width: map.get($device-size, 'md');
    margin: auto;
  }

  @include media-breakpoint-up('xxl') {
    padding: 32px 260px;
    width: map.get($device-size, 'xxl');
  }

  &__header {
    @include display-flex {
      align-items: center;
      justify-content: space-between;
      margin-bottom: 32px;

      @include media-breakpoint-up('xxl') {
        margin-bottom: 24px;
      }
    }

    &__title {
      @include display-flex {
        align-items: flex-end;
      }

      h2,
      h3 {
        color: map.get($color-list, 'green', 500);
        margin-right: 16px;
      }

      h4 {
        color: map.get($color-list, 'neutral', 800);
      }
    }

    &__button {
      display: none;

      @include media-breakpoint-up('xxl') {
        @include display-flex;
        align-items: center;
      }
      &__add {
        svg {
          margin-right: 12px;
        }
      }
    }
  }

  &__footer {
    @include display-flex {
      justify-content: center;
      align-items: center;
    }

    @include media-breakpoint-up('xxl') {
      display: none;
    }

    &__button {
      width: 100%;

      svg {
        margin-right: 12px;
      }

      @include media-breakpoint-up('md') {
        width: 200px;
      }
    }
  }
}

.camp {
  width: 100%;
  margin-bottom: 40px;

  &__help {
    margin-left: 10px;
  }
  @include media-breakpoint-up('md') {
    width: 350px;
  }

  @include media-breakpoint-up('xxl') {
    width: 450px;
  }

  &__list {
    &__view {
      @include display-flex {
        justify-content: space-between;
        flex-wrap: wrap;
      }

      &__wrapper {
        @include display-flex(column);
      }
    }

    &__status {
      width: 100%;
      padding-bottom: 9px;
      border-bottom: 1px solid map.get($color-list, 'neutral', 300);
      margin-bottom: 24px;
      display: flex;
      justify-content: space-between;
      @include body-text;
      color: map.get($color-list, 'neutral', 500);

      &--hidden {
        visibility: hidden;
      }
    }
  }
}
