@use 'sass:map';
@import '/src/assets/scss/shared';

@mixin card-base {
  padding: 1.2rem;
  padding-left: 2.4rem;
  border-radius: $border-radius;
  // border: 1px solid #CCC;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
}

@mixin card-circle($color) {
  content: '';
  position: absolute;
  background-color: $color;
  width: 100%;
  height: 100%;
  z-index: -10;
  left: 0;
  clip-path: circle(35% at 90% 50%);
}

.card {
  @include card-base;
}

.card--plain-shadow {
  @include card-base;
  @include box-shadow;
}

.card--orange-circle {
  @include card-base;
  @include box-shadow;
  overflow: hidden;
  z-index: 0;
  &::before {
    @include card-circle(map.get($color-list, 'orange', 100));
  }
}

.card--green-circle {
  @include card-base;
  @include box-shadow;
  overflow: hidden;
  z-index: 0;
  &::before {
    @include card-circle(map.get($color-list, 'green', 100));
  }
}
