@use "sass:map";
@import "/src/assets/scss/shared";

.page {
  @include display-flex {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color: map.get($color-list, "neutral", 800), $alpha: 0.5);
    z-index: 10;
    align-items: center;
    justify-content: center;
    color: map.get($color-list, "orange", 400);
  }
}
