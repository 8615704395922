@use 'sass:map';
@import '/src/assets/scss/shared';

.page {
  @include display-flex(column) {
    min-height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.header {
  @include display-flex {
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    background-color: rgba($color: #ffffff, $alpha: 0.95);
    backdrop-filter: blur(12px);
  }

  &__brand {
    @include display-flex {
      align-items: center;
      color: map.get($color-list, 'green', 500);
    }
  }

  &__logo {
    @include display-flex {
      position: relative;
      width: 40px;
      height: 40px;
      align-items: center;
      justify-content: center;
      background-color: map.get($color-list, 'green', 300);
      border-radius: 50%;
      overflow: hidden;
      margin-right: 12px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    span {
      @include subtitle-text;
      color: map.get($color-list, 'green', 600);
    }
  }

  &__visit {
    border: 1px solid map.get($color-list, 'green', 400);
    color: map.get($color-list, 'green', 500);

    &:hover {
      background-color: map.get($color-list, 'green', 100);
    }

    &:active {
      background-color: map.get($color-list, 'green', 200);
    }
  }
}

.main {
  @include display-flex(column) {
    background-color: rgba($color: #ffffff, $alpha: 0.95);
    backdrop-filter: blur(12px);
    border-radius: 24px;
    padding: 40px 142px 34px;
    margin-top: 40px;
    width: 732px;
    height: fit-content;
    @include media-breakpoint-down('md') {
      padding: 2.4rem;
      margin-top: 0;
      border-radius: 0;
    }
  }

  @include media-breakpoint-down('md') {
    height: 100%;
  }

  &__wrapper {
    @include display-flex {
      justify-content: center;
      flex-basis: 0;
      flex-grow: 1;
    }
  }
}

.info {
  @include display-flex(column) {
    align-items: center;
  }

  &__logo {
    @include media-breakpoint-down('md') {
      display: none;
    }
    @include display-flex {
      position: relative;
      width: 80px;
      height: 80px;
      align-items: center;
      justify-content: center;
      background-color: map.get($color-list, 'green', 300);
      border-radius: 50%;
      overflow: hidden;
      margin-right: 12px;
      margin-bottom: 24px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    span {
      font-family: 'Rubik-Medium';
      font-weight: 500;
      font-size: 30px;
      line-height: 40px;
      color: map.get($color-list, 'green', 600);
    }
  }

  &__title {
    text-align: center;
    color: map.get($color-list, 'neutral', 800);
    margin-bottom: 24px;
    font-size: 3rem;
  }

  &__desc {
    text-align: center;
    color: map.get($color-list, 'neutral', 600);
    margin-bottom: 32px;
    font-size: 2rem;
    line-height: 30px;
  }
}

.split_line {
  border-top: 1px solid map.get($color-list, 'neutral', 300);
  margin-bottom: 24px;
}

.form {
  margin-bottom: 48px;

  &__legend {
    text-align: center;
    color: map.get($color-list, 'neutral', 600);
    margin-bottom: 24px;
  }

  &__row {
    @include display-flex;

    @include media-breakpoint-down('md') {
      flex-direction: column;
      width: 100%;
      margin: 0 0;
    }
  }

  &__col {
    @include display-flex(column) {
      flex-basis: 0;
      flex-grow: 1;

      &:not(:last-child) {
        margin-right: 24px;
      }
    }
  }

  &__submit {
    &__label {
      visibility: hidden;
    }

    &__wrapper {
      @include display-flex;
    }
  }

  &__accept_terms {
    @include display-flex {
      align-items: center;
      cursor: pointer;
      margin-top: 20px;
    }

    &__checkbox--error span {
      border-color: map.get($color-list, 'red', 500);
    }

    & > span {
      margin-left: 8px;
      @include body-text(2);
      color: map.get($color-list, 'neutral', 800);
      line-height: 1rem;

      button {
        background-color: transparent;
        padding: 0px;
        border: none;
        cursor: pointer;
        @include body-hyperlink-text(2);
        color: map.get($color-list, 'green', 500);
      }
    }
  }
}

.check_mark {
  margin-left: auto;
  margin-right: auto;
  margin-top: 64px;
  margin-bottom: 32px;
  min-width: 64px;
  min-height: 64px;

  @include media-breakpoint-down('xl') {
    margin-top: 0;
  }

  @include media-breakpoint-down('md') {
    margin-top: 0;
  }
}

.success {
  color: map.get($color-list, 'neutral', 800);
  text-align: center;
  margin-bottom: 3.2rem;

  @include media-breakpoint-up('xxl') {
    margin-bottom: 7.2rem;
  }

  @include media-breakpoint-down('md') {
    margin-bottom: 1.6rem;
  }

  &__instructions {
    text-align: center;
    color: map.get($color-list, 'neutral', 800);
    font-size: 1.8rem;

    @include media-breakpoint-up('xl') {
      margin-bottom: 12.8rem;
    }

    @include media-breakpoint-down('xl') {
      margin-bottom: 3.6rem;
    }

    @include media-breakpoint-down('lg') {
      margin-bottom: 2.4rem;
    }

    @include media-breakpoint-down('md') {
      margin-top: 2.4rem;
      margin-bottom: 1.6rem;
    }
  }
}

.powered_by {
  @include display-flex {
    align-items: center;
    justify-content: center;
  }

  @include subtitle-text(3) {
    color: map.get($color-list, 'neutral', 500);
  }

  &__LogoIcon {
    margin-left: 0.8rem;
    width: auto;
    height: 2.4rem;
  }
  @include media-breakpoint-down('md') {
    position: relative;
    bottom: 2.4rem;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
