@use 'sass:math';
@use 'sass:map';
@import '/src/assets/scss/shared';

.top_bar {
  @include display-flex {
    position: relative;
    padding: 48px 24px 8px;
    align-items: center;
    justify-content: space-between;
    background-color: map.get($color-list, 'neutral', 200);

    @include media-breakpoint-up('md') {
      padding: 30px 24px 14px;
    }
  }

  &__logo {
    text-decoration: none;

    svg {
      width: 60px;
      height: 30px;

      @include media-breakpoint-up('md') {
        width: 90px;
        height: 45px;
      }
    }
  }

  &__menu_button {
    cursor: pointer;
    @include display-flex {
      align-items: center;
      justify-content: center;
    }
  }
}

.avatar {
  @include display-flex {
    position: relative;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    background-color: map.get($color-list, 'green', 300);
    border-radius: 50%;
  }

  @include subtitle-text {
    color: map.get($color-list, 'green', 600);
  }
}

.badge {
  @include display-flex {
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -6px;
    bottom: 0px;
    width: 20px;
    height: 16px;
    border-radius: 8px;
    background-color: map.get($color-list, 'orange', 500);
  }

  @include tag-text {
    color: map.get($color-list, 'neutral', 100);
  }
}

.menu {
  @include display-flex(column) {
    position: absolute;
    z-index: 10;
    width: 100vw;
    top: 96px;
    height: calc(100vh - 96px);
    overflow-y: auto;
    right: -100vw;
    padding: 24px 24px 44px;
    background-color: map.get($color-list, 'neutral', 100);
  }

  &__opened {
    right: 0px;
  }

  &__item {
    cursor: pointer;
    @include display-flex {
      position: relative;
      align-items: center;
      padding: 24px 0px;
      text-decoration: none;
      border-bottom: 1px solid map.get($color-list, 'neutral', 300);
    }

    &__icon {
      margin-right: 16px;
    }

    &__text {
      @include subtitle-text;
      color: map.get($color-list, 'neutral', 800);
    }

    &__badge {
      bottom: 28px;
      right: 1px;
    }

    &__logout {
      cursor: pointer;
      border-color: transparent;
      background-color: transparent;
      width: 100%;

      &_text {
        color: map.get($color-list, 'red', 500);
      }
    }
  }

  @include media-breakpoint-only('md') {
    align-items: center;

    &__item {
      width: 350px;
    }
  }
}
