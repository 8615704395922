@use "sass:map";
@import "/src/assets/scss/shared";

.html_editor {
  @include display-flex(column) {
    position: relative;
    margin-bottom: 24px;
  }
}

.caption {
  position: absolute;
  width: 100%;
  text-indent: 8px;
  bottom: -20px;

  @include caption-text(2) {
    color: map.get($color-list, "neutral", 500);
  }

  &__error {
    color: map.get($color-list, "red", 500);
  }
}
