@use "sass:map";
@import "/src/assets/scss/shared";

.layout {
  position: relative;
  width: 100vw;
}

.main {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 96px);
  overflow-x: auto;

  @include media-breakpoint-up("xxl") {
    width: map.get($device-size, "xxl");
    margin: auto;
  }

  &__wrapper {
    @include display-flex;
  }
}
