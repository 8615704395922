@use 'sass:map';
@import '/src/assets/scss/shared';

.container {
  @include display-flex {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba(map.get($color-list, 'neutral', 900), 0.65);
    z-index: 100;
    justify-content: center;
    align-items: center;
  }
}

.modal {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  background-color: map.get($color-list, 'neutral', 100);
  padding: 60px 30px;
  overflow: auto;
  max-height: 100vh;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #e7e7e7;
  }

  @include media-breakpoint-up('md') {
    width: 600px;
    max-height: 90vh;
  }

  @include media-breakpoint-up('xl') {
    width: 450px;
    padding: 50px 60px;
  }
}

.fade {
  &--enter {
    opacity: 0;
  }

  &--enter-active {
    opacity: 1;
    transition: opacity $transition-duration ease-in-out;
  }

  &--exit {
    opacity: 1;
  }

  &--exit-active {
    opacity: 0;
    transition: opacity $transition-duration ease-in-out;
  }
}

.scale {
  &--enter {
    transform: scale(0.6);
  }

  &--enter-active {
    transform: scale(1);
    transition: transform $transition-duration ease-in-out;
  }

  &--exit {
    transform: scale(1);
  }

  &--exit-active {
    transform: scale(0);
    transition: transform $transition-duration ease-in-out;
  }
}
