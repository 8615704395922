@use 'sass:map';
@use 'sass:math';
@import '/src/assets/scss/shared';

.page {
  padding: 40px 24px;

  &__title {
    text-align: center;
    color: map.get($color-list, 'green', 500);
    margin-bottom: 32px;
    flex: 1;
    text-align: center;
  }

  @include media-breakpoint-up('md') {
    padding-top: 80px;
    padding-left: 210px;
    padding-right: 210px;
  }
}

.profile_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 16px;
  @include media-breakpoint-up('xxl') {
    width: 730px;
    margin: auto;
  }
}

.helpModal {
  margin-left: auto;
  margin-bottom: 32px;
}

.form {
  @include media-breakpoint-up('xxl') {
    width: 730px;
    margin: auto;
  }

  &__legend {
    @include body-text {
      color: map.get($color-list, 'neutral', 500);
    }

    padding-bottom: 8px;
    border-bottom: 1px solid map.get($color-list, 'neutral', 300);
    margin-bottom: 24px;

    @include media-breakpoint-up('xxl') {
      margin-bottom: 40px;
    }
  }

  &__row {
    @include display-flex(column) {
      @include media-breakpoint-up('xxl') {
        flex-direction: row;
        justify-content: space-between;
        padding-left: 24px;
        padding-right: 24px;
      }
    }

    &__password {
      @include display-flex {
        align-items: center;
        justify-content: center;
        height: 72px;
        margin-top: 24px;
        border-radius: 12px;
      }

      @include media-breakpoint-up('xxl') {
        margin-top: 0px;
      }
    }
  }

  &__field {
    @include media-breakpoint-up('xxl') {
      width: 330px;
      margin-bottom: 40px;
    }
  }

  &__change_password {
    svg {
      margin-right: 12px;
    }
  }

  &__buttons {
    @include display-flex {
      justify-content: center;
      margin-top: 72px;

      @include media-breakpoint-up('xxl') {
        margin-top: 48px;
      }
    }
  }

  &__cancel {
    margin-right: 24px;
    width: 150px;

    @include media-breakpoint-up('xxl') {
      width: 110px;
    }
  }

  &__save {
    width: 150px;

    @include media-breakpoint-up('xxl') {
      width: 90px;
    }
  }
}
