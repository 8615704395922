@use "sass:map";
@import "/src/assets/scss/shared";

.page {
  @include display-flex(column) {
    position: relative;
    padding-left: 24px;
    padding-right: 24px;
    z-index: 20;
  }

  @include media-breakpoint-up("md") {
    width: 400px;
    margin-left: auto;
    margin-right: auto;
  }

  @include media-breakpoint-up("xxl") {
    width: 450px;
    min-height: 600px;
    padding: 60px;
    border-radius: 24px;
    background-color: $color-white;

    &__container {
      @include display-flex {
        height: 100vh;
        min-height: 900px;
        background-color: map.get($color-list, "neutral", 200);
      }
    }

    &__wrapper {
      @include display-flex {
        align-items: center;
        justify-content: space-between;
        width: map.get($device-size, "xxl");
        padding: 120px 496px 180px;
        margin: auto;
      }
    }
  }

  &__bg {
    position: absolute;
    z-index: 10;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0px;
    overflow: hidden;

    svg {
      position: absolute;
      width: 100%;
      height: auto;
      transform: rotate(30deg);

      &:first-child {
        top: -350px;
        left: -250px;

        @include media-breakpoint-up("md") {
          width: 60%;
          left: -200px;
        }

        @include media-breakpoint-up("xxl") {
          top: -430px;
          left: -100px;
          width: 100%;
          height: 2300px;
        }
      }

      &:last-child {
        right: -100px;
        bottom: -400px;

        @include media-breakpoint-up("md") {
          width: 60%;
          bottom: -450px;
        }

        @include media-breakpoint-up("xxl") {
          display: none;
        }
      }
    }
  }
}

.logo {
  margin: 184px auto 54px;
  min-width: 110px;
  min-height: 56px;

  @include media-breakpoint-up("xxl") {
    margin-top: 0px;
    margin-bottom: 72px;
  }
}

.check_mark {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 36px;
  min-width: 64px;
  min-height: 64px;
}

.success {
  color: map.get($color-list, "neutral", 800);
  text-align: center;
  margin-bottom: 32px;

  @include media-breakpoint-up("xxl") {
    margin-bottom: 16px;
  }
}

.message {
  margin-bottom: 230px;

  @include body-text(2) {
    text-align: center;
    color: map.get($color-list, "neutral", 500);
  }

  @include media-breakpoint-up("xxl") {
    margin-bottom: 80px;
  }
}

.get_started {
  margin-bottom: 20px;

  @include media-breakpoint-up("xxl") {
    width: 150px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0px;
  }
}
