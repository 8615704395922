@import '/src/assets/scss/shared';

.badge {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 0 .5rem;
	position: relative;
	height: 1.6rem;
	border-radius: .8rem;
	background-color: #fcad37;
	font-family: "Rubik-Medium";
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.2rem;
	letter-spacing: 1.2px;
	color: #fff;
}

