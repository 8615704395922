@use "sass:map";
@import "grid";

$color-white: #fff;
$color-black: #000;

$color-list: (
  "neutral": (
    100: #fff,
    200: #f8f8f8,
    300: #e7e7e7,
    400: #cdcdcd,
    500: #999999,
    600: #808080,
    700: #666666,
    800: #333333,
    900: #000,
  ),
  "green": (
    100: #f1f8f0,
    200: #ddedda,
    300: #c4e0bf,
    400: #91c688,
    500: #56a748,
    600: #45863a,
    700: #34642b,
    800: #22431d,
    900: #1a3216,
  ),
  "orange": (
    100: #fff8ef,
    200: #feefd7,
    300: #fee2b9,
    400: #fdca7d,
    500: #fcad37,
    600: #ca8a2c,
    700: #976821,
    800: #654516,
    900: #32230b,
  ),
  "red": (
    100: #fbeeee,
    200: #f6d4d4,
    300: #efb4b3,
    400: #e47e7d,
    500: #d22827,
    600: #a8201f,
    700: #7e1817,
    800: #541010,
    900: #2a0808,
  ),
  "blue": (
    100: #ebf2ff,
    200: #ccddff,
    300: #a6c4ff,
    400: #5992ff,
    500: #0057ff,
    600: #0045cc,
    700: #003499,
    800: #002366,
    900: #001a4c,
  ),
);
