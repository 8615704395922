@use "sass:map";
@use "sass:math";
@import "/src/assets/scss/shared";

.page {
  padding: 16px 2.4rem 4rem;
}

.form {
  @include media-breakpoint-up("md") {
    width: 50rem;
    margin: auto;
  }

  @include media-breakpoint-up("xxl") {
    width: 730px;
  }

  fieldset {
    padding: 0;
    border: none;
    margin: 0;
    margin-bottom: 44px;

    @include media-breakpoint-up("xxl") {
      margin-bottom: 84px;
    }
  }

  &__legend {
    width: 100%;
    padding-bottom: 8px;
    border-bottom: 1px solid map.get($color-list, "neutral", 300);
    margin-bottom: 2.4rem;

    @include body-text {
      color: map.get($color-list, "neutral", 500);
    }

    @include media-breakpoint-up("xxl") {
      margin-bottom: 4rem;
    }
  }

  &__row {
    @include display-flex(column) {
      @include media-breakpoint-up("xxl") {
        flex-direction: row;
        justify-content: space-between;
        padding-left: 2.4rem;
        padding-right: 2.4rem;
      }

      &__reverse {
        @include media-breakpoint-down("lg") {
          &:first-of-type {
            flex-direction: column-reverse;
          }
        }
      }

      &__color_palette {
        justify-content: flex-start;
      }
    }
  }

  &__seasons {
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  &__col {
    @include display-flex(column) {
      flex-basis: 0;
      flex-grow: 1;
      justify-content: center;

      &:not(:last-child) {
        margin-right: 2.4rem;
      }
    }
  }

  &__add_logo {
    @include display-flex(column) {
      position: relative;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 230px;
      border: 1px solid transparent;
      background-color: map.get($color-list, "neutral", 200);
      border-radius: 2.4rem;
      margin-bottom: 30px;

      @include media-breakpoint-up("xxl") {
        height: 305px;
        margin-bottom: 0px;
      }
    }

    &--error {
      border-color: map.get($color-list, "red", 600);
      background-color: map.get($color-list, "red", 100);
    }

    &__name {
      margin-bottom: 12px;
      @include caption-text {
        color: map.get($color-list, "neutral", 500);
      }

      @include media-breakpoint-up("xxl") {
        margin-bottom: 16px;
      }
    }

    &__preview {
      width: 150px;
      height: 150px;
      object-fit: cover;
      border-radius: 2.4rem;
      cursor: pointer;

      @include media-breakpoint-up("xxl") {
        width: 170px;
        height: 170px;
        margin-bottom: 12px;
      }
    }

    &__button {
      @include display-flex(column) {
        position: relative;
        align-items: center;
        justify-content: center;
        width: 150px;
        height: 150px;
        background-color: map.get($color-list, "neutral", 100);
        border-color: transparent;
        border-radius: 2.4rem;
        cursor: pointer;

        @include media-breakpoint-up("xxl") {
          width: 170px;
          height: 170px;
        }
      }

      &::before {
        position: absolute;
        content: "";
        width: calc(100% - 12.5px);
        height: calc(100% - 12.5px);
        border: 1px dashed map.get($color-list, "green", 200);
        border-radius: 16px;
      }

      p {
        &:first-of-type {
          margin-bottom: 6px;

          @include button-text(2) {
            text-transform: uppercase;
            color: map.get($color-list, "orange", 600);
          }
        }

        &:last-of-type {
          @include caption-text {
            color: map.get($color-list, "neutral", 500);
          }
        }
      }
    }

    &__icon {
      margin-bottom: 16px;
    }

    &__caption {
      position: absolute;
      bottom: 30px;
      @include caption-text {
        color: map.get($color-list, "red", 600);
      }
    }
  }

  &__color_picker {
    @include display-flex;

    &__wrapper {
      @include display-flex(column) {
        margin-bottom: 16px;
      }

      @include media-breakpoint-up("xxl") {
        &:not(:last-child) {
          margin-right: 90px;
        }
      }
    }

    &__label {
      margin-bottom: 10px;
      @include subtitle-text(2);
      color: map.get($color-list, "neutral", 800);
    }

    &__preview {
      width: 150px;
      height: 2.4rem;
      border-radius: 6px;
      margin-right: 2.4rem;

      @include media-breakpoint-up("xxl") {
        width: 64px;
        height: 64px;
        margin-right: 16px;
        border-radius: 12px;
      }
    }

    &__text {
      @include display-flex {
        flex-grow: 1;
      }

      @include media-breakpoint-up("xxl") {
        @include display-flex(column) {
          justify-content: center;
        }
      }

      button {
        background-color: transparent;
        border: none;
        padding: 0px;
        text-align: left;
        width: fit-content;
        height: 2.4rem;
        cursor: pointer;

        @include button-text(2) {
          color: map.get($color-list, "orange", 600);
          text-transform: uppercase;
        }
      }
    }

    &__color {
      color: map.get($color-list, "neutral", 600);
      margin-right: 1rem;
      @include media-breakpoint-down("lg") {
        margin-right: auto;
      }
    }

    &__btn {
      text-decoration: underline;
    }
  }

  &__buttons {
    @include display-flex {
      justify-content: center;
      margin-top: 7.2rem;

      @include media-breakpoint-up("xxl") {
        margin-top: 4.8rem;
      }
    }
  }

  &__cancel {
    margin-right: 2.4rem;
    width: 150px;

    @include media-breakpoint-up("xxl") {
      width: 110px;
    }
  }

  &__save {
    width: 150px;

    @include media-breakpoint-up("xxl") {
      width: 90px;
    }
  }
}

.season {
  margin-right: 16px;
  margin-bottom: 8px;

  &__button {
    width: 150px;

    &__wrapper {
      @include media-breakpoint-down("lg") {
        flex-basis: 100%;
        margin-top: 2.4rem;
      }

      @include media-breakpoint-up("xxl") {
        margin-left: 8px;
      }

      &__empty {
        margin-top: 0px;
        margin-left: 0px;
      }
    }
  }
}

.role {
  @include display-flex {
    position: relative;
    align-items: center;
    background-color: map.get($color-list, "neutral", 200);
    border-radius: 12px;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 8px 16px 12px;
    margin-right: 0px !important;
    margin-bottom: 8px;
    height: 7.2rem;
    width: 33rem;
    flex-grow: unset;
    flex-basis: unset;
  }

  &s__wrapper {
    flex-wrap: wrap;
  }

  &__no {
    @include body-text(2) {
      color: map.get($color-list, "neutral", 500);
    }
    margin-right: 8px;
  }

  &__name {
    @include subtitle-text(2);
    color: map.get($color-list, "neutral", 800);
    margin-right: 16px;
  }

  &__text {
    @include body-text(2) {
      color: map.get($color-list, "green", 500);
    }
  }

  &__email {
    flex-basis: 100%;
    text-indent: 16px;

    @include caption-text {
      color: map.get($color-list, "neutral", 500);
    }
  }

  &__remove {
    @include display-flex {
      position: absolute;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      border: none;
      cursor: pointer;
      padding: 0;
      top: 9px;
      right: 9px;
    }
  }

  &__add_role {
    svg {
      margin-right: 12px;
    }

    &__wrapper {
      margin-top: 16px;
    }
  }
}
