@use "sass:map";
@import "/src/assets/scss/shared";

.container {
  @include display-flex(column) {
    position: relative;
    flex-basis: 0;
    flex-grow: 1;
    margin-bottom: 24px;
  }
}

.tag {
  @include display-flex {
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: map.get($color-list, "green", 200);
    height: 40px;
    border-radius: 24px;
    padding: 8px 16px;
    margin: 4px;
  }

  @include body-text {
    color: map.get($color-list, "green", 600);
  }

  @include media-breakpoint-up("xxl") {
    height: 24px;
    border-radius: 12px;
    padding: 4px 8px;
    @include caption-text;
  }

  & > svg {
    width: 16px;
    height: 16px;
    margin-left: 8px;

    * {
      stroke: map.get($color-list, "green", 600);
      fill: map.get($color-list, "green", 100);
    }

    @include media-breakpoint-up("xxl") {
      width: 14px;
      height: 14px;
    }
  }

  &:hover {
    background-color: map.get($color-list, "red", 200);
    color: map.get($color-list, "red", 700);

    & > svg * {
      stroke: map.get($color-list, "red", 500);
      fill: map.get($color-list, "red", 100);
    }
  }

  &s__wrapper {
    @include display-flex {
      position: relative;
      border-radius: 24px;
      outline: 1px solid map.get($color-list, "neutral", 400);
      flex-wrap: wrap;

      @include media-breakpoint-up("xxl") {
        padding: 4px;
      }
    }

    &--error {
      outline-color: map.get($color-list, "red", 500);
    }
  }

  &__input {
    @include display-flex;
    border: unset;
    padding: 0;
    outline: unset;
    color: map.get($color-list, "neutral", 800);
    transition-duration: unset;
    @include body-text;
    flex-basis: 0;
    flex-grow: 1;
    margin: 4px;
    height: 40px;
    padding: 8px 16px;

    &::placeholder {
      color: map.get($color-list, "neutral", 500);
    }

    @include media-breakpoint-up("xxl") {
      @include caption-text;
      height: 24px;
      padding: 4px 8px;
    }

    &__label {
      @include subtitle-text(2);
      color: map.get($color-list, "neutral", 800);
      text-indent: 8px;
    }

    &__required {
      color: map.get($color-list, "green", 500);
    }

    &__optional {
      @include body-text(2) {
        color: map.get($color-list, "neutral", 600);
      }
    }
  }
}

.caption {
  position: absolute;
  width: 100%;
  text-indent: 8px;
  bottom: -20px;
  @include caption-text(2) {
    color: map.get($color-list, "neutral", 500);
  }

  &__error {
    color: map.get($color-list, "red", 500);
  }
}
