@use "sass:map";
@import "/src/assets/scss/shared";

.page {
  width: 732px;
  margin: 40px auto 128px;

  &__header {
    @include display-flex {
      align-items: center;
      margin-bottom: 32px;
    }
  }

  &__footer {
    @include display-flex {
      align-items: center;
      justify-content: center;
      height: 100px;
    }
  }
}

.section {
  background-color: map.get($color-list, "neutral", 200);
  border-radius: 24px;
  padding: 24px 24px 44px;
  margin-bottom: 24px;

  &__title {
    margin-bottom: 24px;
    @include subtitle-text {
      color: map.get($color-list, "green", 500);
    }
  }
}

.ambassador_info {
  &__wrapper {
    margin-top: 40px;
    margin-bottom: 100px;

    table {
      width: 100%;

      tr th:first-child,
      tr td:first-child {
        padding-left: 16px;
      }

      & > tbody tr:first-child td {
        padding-top: 16px;
      }
    }
  }

  &__title {
    margin-bottom: 16px;
    @include subtitle-text {
      color: map.get($color-list, "green", 500);
    }
  }

  &__th {
    @include body-text(2) {
      color         :  map.get($color-list, "neutral", 500);
      text-align    :  left;
      border-bottom :  1px solid map.get($color-list, "neutral", 300);
    }

    &__wrap {
      max-width: 6rem;
    }

    &__unique_link {
      width :  60px;
      color :  map.get($color-list, "green", 500);
    }

    &__dashboard_link {
      width: 80px;
      color: map.get($color-list, "orange", 700);
    }
  }

  &__td {
    padding-top    :  8px;
    padding-bottom :  8px;
    border-bottom  :  1px solid map.get($color-list, "neutral", 300);
    @include body-text {
      color: map.get($color-list, "neutral", 800);
    }

    &__name {
      a {
        @include body-hyperlink-text {
          color: map.get($color-list, "neutral", 800);
        }
      }
    }

    &__start_date {
      color: map.get($color-list, "neutral", 400);
    }

    .copy {
      @include button-text(2) {
        text-transform: uppercase;
        cursor: pointer;
      }

      &__unique_link {
        color: map.get($color-list, "green", 500);
      }

      &__dashboard_link {
        color: map.get($color-list, "orange", 700);
      }
    }

    &__total {
      @include subtitle-text {
        color: map.get($color-list, "green", 500);
      }
    }
  }
}

.form {
  &__row {
    @include display-flex;
  }

  &__col {
    @include display-flex(column) {
      flex-basis: 0;
      flex-grow: 1;

      &:not(:last-child) {
        margin-right: 24px;
      }
    }
  }
}

.text {
  &__person {
    &__name {
      color: map.get($color-list, "neutral", 800);
    }

    &__type {
      margin: auto auto 4px 16px;

      @include subtitle-text {
        color: map.get($color-list, "green", 300);
        text-transform: capitalize;
      }
    }
  }
}

.button {
  &__icon {
    margin-right: 8px;
  }

  &__back {
    margin-right: 16px;
  }

  &__launch {
    background-color: map.get($color-list, "green", 300);
    color: map.get($color-list, "green", 600);

    &:hover {
      background-color: map.get($color-list, "green", 200);
    }

    &:active,
    &:focus {
      background-color: map.get($color-list, "green", 400);
    }
  }
}
