@use 'sass:math';
@use 'sass:map';
@import '/src/assets/scss/shared';

.subscription {
  @include display-flex(column) {
    position: relative;
    background-color: map.get($color-list, 'neutral', 200);
    border: 1px solid transparent;
    border-radius: 24px;
    padding: 24px 24px 16px;
    border-color: map.get($color-list, 'green', 500);
  }
  cursor: pointer;

  &__name {
    margin-bottom: 10px;
    @include headline-text(4);
    color: map.get($color-list, 'neutral', 800);
    font-family: 'Rubik-Regular';
  }

  &__description {
    margin-bottom: 0.8rem;

    @include body-text(3) {
      color: map.get($color-list, 'neutral', 500);
    }
  }
  &__note {
    text-align: center;
    flex-direction: row;
    justify-content: space-evenly;
    display: flex;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5rem;

    @include body-text(3) {
      color: map.get($color-list, 'neutral', 500);
    }
  }
  &__price {
    @include subtitle-text(4);
    margin-bottom: 10px;
  }
}

.products {
  flex-direction: row;
  justify-content: space-evenly;
  display: flex;
  margin-top: 40px;
  margin-bottom: 32px;
}

.field {
  &__type {
    @include display-flex {
      position: relative;
      border-radius: 24px;
      text-align: center;
      align-items: center;
      cursor: pointer;
      padding: 1.6rem 3.6rem;

      border-radius: 1.6rem;
      border: 2px solid;
      border-color: map.get($color-list, 'neutral', 500);
    }
    &__details {
      width: 148px;
    }

    &:hover {
      border-color: map.get($color-list, 'orange', 800);
    }
    &__active {
      background-color: map.get($color-list, 'orange', 200);
      border-color: map.get($color-list, 'orange', 400);
    }
  }
}

.plan_detail {
  text-align: center;
  color: map.get($color-list, 'green', 500);
  margin-bottom: 32px;
}
.term_detail {
  text-align: left;
  width: 400px;
  color: map.get($color-list, 'green', 500);
  margin: 0px auto 20px auto;
}

.promo {
  width: 350px;
  margin-left: auto;
  margin-right: auto;
  &__input {
    padding: 0 10px;
  }
}

.accordion_section {
  padding: 10px 10px;
  margin-bottom: 30px;
}

.submit_btn {
  margin: 10px auto 30px auto;
}
