@use 'sass:map';
@use 'sass:math';
@import '/src/assets/scss/shared';

.section {
  @include display-flex(column) {
    min-width: 42rem;
    max-width: 92rem;
  }

  .profile_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .helpModal {
    margin-left: auto;
    margin-bottom: 4.8rem;
  }

  &__title {
    color: map.get($color-list, 'green', 500);
    margin-bottom: 4.8rem;
  }

  &__description {
    margin-left: 2.4rem;

    @include body-text(2) {
      color: map.get($color-list, 'neutral', 500);
    }
  }

  &__footer {
    @include display-flex {
      align-items: center;
      justify-content: center;
      height: 10rem;
    }
  }
}

.accordion_section {
  margin: 0px -2.4rem 1.6rem;

  &__subtitle {
    margin: 1.6rem 0;
    @include subtitle-text;
    color: map.get($color-list, 'neutral', 800);
  }
}

.split_line {
  border: 1px solid map.get($color-list, 'neutral', 300);
  margin: 3.2rem 0;
}

.form {
  fieldset {
    border: none;
    background-color: map.get($color-list, 'neutral', 100);
    border-radius: 2.4rem;
    padding: 3.2rem 9.6rem 5.2rem;
    margin-bottom: 2.4rem;
  }

  &__row {
    @include display-flex {
      justify-content: space-between;
    }
  }

  &__col {
    @include display-flex(column) {
      flex-basis: 0;
      flex-grow: 1;

      &:not(:last-child) {
        margin-right: 2.4rem;
      }
    }
  }
}

.button {
  &__email {
    &s__wrapper {
      @include display-flex;
      margin-bottom: 3.2rem;
    }

    &__choose {
      margin-right: 2.4rem;
    }

    &__view_all__wrapper {
      @include display-flex;
    }

    &__icon {
      margin-right: 0.8rem;

      &--disabled * {
        fill: map.get($color-list, 'neutral', 200);
        stroke: map.get($color-list, 'neutral', 300);
      }
    }
  }

  &__back,
  &__next {
    margin-right: 1.6rem;
  }
  &__flex {
    display: flex;
    flex-direction: column;
  }

  &__uploadBgImg {
    &__icon {
      margin-right: 0.8rem;

      &--disabled * {
        fill: map.get($color-list, 'neutral', 200);
        stroke: map.get($color-list, 'neutral', 400);
      }
    }
  }
}

.field {
  &__bgImg {
    @include display-flex(column) {
      position: absolute;
      align-items: center;
    }

    &--change {
      right: 1.6rem;
      bottom: 0.6rem;
    }

    &__wrapper {
      @include display-flex {
        position: relative;
        flex-basis: 0;
        flex-grow: 1;
        align-items: center;
        justify-content: center;
        margin-bottom: 1.6rem;
      }
    }

    &__preview {
      @include display-flex {
        position: relative;
        flex-basis: 0;
        flex-grow: 1;
        align-items: center;
        justify-content: center;
        height: 22.4rem;
        background-color: map.get($color-list, 'neutral', 100);
        border: 1px solid transparent;
        border-radius: 1.6rem;
        overflow: hidden;
      }

      &--error {
        border-color: map.get($color-list, 'red', 500);
        background-color: map.get($color-list, 'red', 100);
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    &__placeholder {
      margin: 1.2rem auto;
      width: auto;
      height: 20rem;
    }

    &__text {
      margin-bottom: 4px;
      @include subtitle-text;
      color: map.get($color-list, 'neutral', 800);

      &__desc {
        @include caption-text {
          color: map.get($color-list, 'neutral', 500);
          margin-bottom: 2.4rem;
        }
      }
    }
    &__img__limit {
      @include subtitle-text;
      color: map.get($color-list, 'neutral', 800);
      font-size: 10px;
    }
  }

  &__email__tag {
    margin: 4px;
    border-radius: 2.4rem;

    &s__wrapper {
      @include display-flex {
        flex-wrap: wrap;
        margin: -4px -4px 2.4rem;
        max-height: 16rem;
        overflow: hidden;

        @for $scale from 1 to 10 {
          &--all--#{$scale}x {
            max-height: calc(100vh * #{$scale});
          }
        }
      }
    }
  }

  &__public_link {
    &__input {
      margin-right: 2.4rem;
    }

    &__copy {
      margin-top: 2.4rem;
    }
    &__copy_widget {
      margin-top: 2.4rem;
      margin-bottom: 2.4rem;
    }

    &__desc {
      @include body-text(2) {
        color: map.get($color-list, 'neutral', 500);
        font-style: italic;
      }
    }
  }

  &__email_to {
    @include display-flex {
      padding: 0.8rem 0;
      width: 100%;
      border-bottom: 1px solid map.get($color-list, 'neutral', 300);
    }

    &__checkbox {
      margin-right: auto;
    }
  }
}

.landing {
  &__preview {
    &__container {
      @include display-flex(column);

      label {
        visibility: hidden;
        @include subtitle-text(2);
        color: map.get($color-list, 'neutral', 800);
        text-indent: 0.8rem;
      }
    }

    &__button {
      margin-left: auto;

      &__wrapper {
        @include display-flex;
      }
    }
  }
}

.dashboard {
  &__wrapper {
    position: relative;
  }

  &__info {
    margin-bottom: 3.6rem;

    .textarea {
      height: 6.4rem;
    }
  }

  &__preview {
    &__button {
      margin-left: auto;

      &__wrapper {
        @include display-flex;
      }
    }
  }
}

.emails_to {
  &__wrapper {
    @include display-flex(column) {
      &:not(:last-child) {
        margin-bottom: 3.6rem;
      }
    }
  }

  &__subtitle {
    margin-bottom: 1.6rem;
  }
}
