@use 'sass:map';
@use 'sass:math';
@import '/src/assets/scss/shared';

.prospect_reward {
  position: relative;
  background-color: map.get($color-list, 'neutral', 200);
  border-radius: 24px;
  padding: 30px 24px 40px;
}

.title {
  margin-bottom: 24px;
  @include subtitle-text;
  color: map.get($color-list, 'neutral', 800);

  &__dis {
    display: flex;
    justify-content: space-between;
  }
}
.helpModal {
  margin-left: 0.5rem;
  cursor: auto;
}

.row {
  @include display-flex;
}

.col {
  @include display-flex(column) {
    flex-basis: 0;
    flex-grow: 1;

    &:not(:last-child) {
      margin-right: 24px;
    }
  }

  &--reversed {
    @include display-flex(column-reverse);
  }
}

.field {
  &__type {
    @include display-flex {
      position: relative;
      flex-basis: 0;
      flex-grow: 1;
      align-items: center;
      cursor: pointer;
      padding: 16px;
      background-color: map.get($color-list, 'orange', 200);
      border-radius: 16px;
      border: 1px solid transparent;
    }

    &:not(:last-child) {
      margin-right: 24px;
    }

    &--selected {
      background-color: map.get($color-list, 'neutral', 100);
      border-color: map.get($color-list, 'orange', 500);
    }

    &--disabled {
      background-color: map.get($color-list, 'neutral', 200);
      border-color: map.get($color-list, 'neutral', 300);
    }

    &s__wrapper {
      @include display-flex {
        justify-content: space-between;
        margin-bottom: 24px;
      }
    }

    &__text {
      @include subtitle-text(3) {
        color: map.get($color-list, 'neutral', 500);
      }

      &--selected {
        color: map.get($color-list, 'neutral', 800);
      }

      &--disabled {
        color: map.get($color-list, 'neutral', 400);
      }
    }

    &__icon {
      margin-left: auto;
    }
  }

  &__is_upload {
    @include display-flex {
      align-items: center;
      cursor: pointer;
      margin-bottom: 24px;
    }

    &__text {
      @include body-text(2);

      &--disabled {
        color: map.get($color-list, 'neutral', 400);
      }
    }
  }

  &__gap {
    height: 24px;
    margin-bottom: 24px;
  }

  &__image__top {
    @include display-flex {
      background-color: map.get($color-list, 'neutral', 100);
      padding: 8px 16px 8px 8px;
      border-radius: 16px;
      cursor: pointer;
      margin-bottom: 18px;
    }
  }
  &__image {
    @include display-flex {
      background-color: map.get($color-list, 'neutral', 100);
      padding: 8px 16px 8px 8px;
      border-radius: 16px;
      cursor: pointer;
    }

    &__preview {
      @include display-flex {
        position: relative;
        align-items: center;
        justify-content: center;
        margin-right: 16px;
        width: 110px;
        min-width: 110px;
        height: 96px;
        border-radius: 12px;
        overflow: hidden;
      }

      &::before {
        content: '';
        position: absolute;
        width: 102px;
        height: 88px;
        left: 4px;
        top: 4px;
        border: 1px dashed map.get($color-list, 'orange', 300);
        border-radius: 12px;
      }

      img {
        position: relative;
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover;
      }

      svg {
        width: 50%;
        height: auto;
      }
    }

    &__info {
      @include display-flex(column);

      &__filename {
        margin-top: 16px;
        @include body-text;
        max-width: 170px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        text-align: left;
      }

      &__text {
        margin-top: auto;

        @include button-text(2) {
          color: map.get($color-list, 'orange', 600);
          text-transform: uppercase;
        }

        &--disabled {
          color: map.get($color-list, 'neutral', 400);
        }
      }
      &__img_limit {
        font-size: 12px;
        color: map.get($color-list, 'orange', 600);
      }
    }
  }

  &__amount {
    position: relative;

    &__unit {
      position: absolute;
      left: 16px;
      top: 35px;
      z-index: 5;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;

      &--disabled {
        color: map.get($color-list, 'neutral', 400);
      }

      &--placeholder {
        color: map.get($color-list, 'neutral', 500);
      }
    }

    &__input {
      padding-left: 32px !important;

      &--type-2 {
        max-width: 152px;
      }
    }
  }

  &__promocode {
    &__input {
      &--type-2 {
        max-width: 152px;
      }
    }
  }
}

.button {
  &__close {
    @include display-flex {
      position: absolute;
      background-color: transparent;
      border: none;
      cursor: pointer;
      top: 24px;
      right: 24px;
      padding: 0px;
    }
  }
}

.icon {
  &__check_box {
    @include display-flex {
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border: 1px solid map.get($color-list, 'orange', 500);
      border-radius: 4px;
      color: map.get($color-list, 'orange', 700);
      margin-right: 1rem;
    }

    &--checked {
      border-color: map.get($color-list, 'orange', 400);
      background-color: map.get($color-list, 'orange', 400);
    }

    &--disabled {
      border-color: map.get($color-list, 'neutral', 400);
      background-color: map.get($color-list, 'neutral', 200);

      &.icon__check_box--checked {
        border-color: map.get($color-list, 'neutral', 300);
        background-color: map.get($color-list, 'neutral', 300);

        * {
          stroke: map.get($color-list, 'neutral', 400);
        }
      }
    }
  }
}
