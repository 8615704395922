@use 'sass:map';
@use 'sass:math';
@import '/src/assets/scss/shared';

.page {
  @include display-flex(column) {
    background-color: $color-white;
    min-height: 100vh;
    padding-left: 24px;
    padding-right: 24px;
  }

  @include media-breakpoint-up('md') {
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 56px;
  }

  @include media-breakpoint-up('xxl') {
    width: 560px;
    min-height: 748px;
    padding: 50px 100px;
    border-radius: 24px;
    margin: unset;
  }

  &__container {
    @include media-breakpoint-up('xxl') {
      @include display-flex {
        height: 100vh;
        min-height: 900px;
        background-color: map.get($color-list, 'neutral', 200);
      }
    }
  }

  &__wrapper {
    @include media-breakpoint-up('xxl') {
      @include display-flex {
        width: map.get($device-size, 'xxl');
        padding: 64px 140px;
        margin: auto;
      }
    }
  }

  &__illustration {
    @include media-breakpoint-up('xxl') {
      margin: 196px auto 128px 80px;
    }
  }
}

.logo {
  margin: 48px auto 32px;
  width: 110px;
  height: 56px;

  @include media-breakpoint-up('xxl') {
    margin-left: 0px;
    margin-top: 0px;
  }
}

.description {
  margin-bottom: 32px;
  color: map.get($color-list, 'green', 500);
  text-align: center;

  @include media-breakpoint-up('xxl') {
    text-align: left;
  }
}

.login_with {
  @include subtitle-text(2) {
    color: map.get($color-list, 'neutral', 500);
  }

  margin-bottom: 16px;
}

.social_button {
  @include display-flex {
    align-items: center;
    padding: 16px 24px;
    width: 150px;
    background-color: map.get($color-list, 'neutral', 200);
    border-radius: 12px;
    border: none;
  }

  &s__container {
    @include display-flex {
      justify-content: space-between;
      margin-bottom: 56px;
    }
  }

  span {
    @include body-text(2);
    color: map.get($color-list, 'neutral', 800);
    margin-left: 15px;
  }

  @include media-breakpoint-up('xxl') {
    width: 155px;

    &s__container {
      margin-bottom: 40px;
    }
  }
}

.input {
  &__password {
    margin-bottom: 36px;

    @include media-breakpoint-up('xxl') {
      margin-bottom: 24px;
    }
  }
}

.options {
  @include display-flex {
    align-items: center;
    justify-content: space-between;
    margin-bottom: 70px;
  }

  @include media-breakpoint-up('xxl') {
    margin-bottom: 54px;
  }
}

.forgot_password {
  @include body-hyperlink-text {
    color: map.get($color-list, 'green', 500);
  }
}

.button {
  &__login {
    margin-bottom: 16px;
  }

  &__sign_up {
    margin-bottom: 20px;
  }

  @include media-breakpoint-up('md') {
    &s__container {
      @include display-flex {
        justify-content: space-evenly;
      }
    }

    &__sign_up,
    &__login {
      width: 160px;
      margin-bottom: 0;

      @include media-breakpoint-up('xxl') {
        width: 150px;
      }
    }
  }
}
.signup_link {
  @include body-hyperlink-text {
    color: map.get($color-list, 'green', 500);
  }
}
.options_signup {
  @include display-flex {
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
}
