@use "sass:map";
@import "/src/assets/scss/shared";

.modal {
  @include media-breakpoint-only("xs") {
    width: 100%;
    height: 100%;
    border-radius: 0px;
    padding: 164px 24px 24px;
  }

  @include media-breakpoint-up("xxl") {
    width: 684px;
    padding: 60px 176px 75px;
  }

  * {
    transition-duration: unset;
  }

  &__content {
    @include display-flex(column) {
      height: 100%;
    }
  }
}

.title {
  color: map.get($color-list, "neutral", 800);
  margin-bottom: 40px;
  text-align: center;
}

.close {
  position: absolute;
  background-color: transparent;
  padding: 0px;
  border: none;
  cursor: pointer;
  top: 16px;
  right: 16px;
}

.form {
  @include display-flex(column) {
    flex-grow: 1;
  }

  &__row {
    @include display-flex;
  }

  &__col {
    @include display-flex(column) {
      flex-basis: 0;
      flex-grow: 1;

      &:not(:last-child) {
        margin-right: 24px;
      }
    }
  }

  &__buttons {
    @include display-flex {
      align-items: center;
      justify-content: center;
      margin-top: auto;
    }

    @include media-breakpoint-up("xxl") {
      margin-top: 40px;
    }
  }

  &__cancel {
    width: 150px;
    margin-right: 24px;

    @include media-breakpoint-up("xxl") {
      width: 110px;
    }
  }

  &__save {
    width: 150px;

    @include media-breakpoint-up("xxl") {
      width: 110px;
    }
  }
}
