@use "sass:map";
@import "/src/assets/scss/shared";

.breadcrumb {
  @include display-flex {
    align-items: center;
    padding: 24px;

    @include media-breakpoint-up("xxl") {
      padding-left: 140px;
      padding-right: 140px;
    }
  }

  &__arrow {
    transform: rotate(90deg);
    margin-left: 16px;
    margin-right: 16px;
  }

  &__item {
    @include subtitle-text(3) {
      text-decoration: none;

      &:not(:last-child) {
        color: map.get($color-list, "neutral", 600);
      }

      &:last-child {
        color: map.get($color-list, "green", 500);
      }
    }
  }
}
