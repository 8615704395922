@use 'sass:math';
@use 'sass:map';
@import '/src/assets/scss/shared';

.checkbox {
  @include display-flex {
    align-items: center;
  }

  &__dis {
    display: flex;
    justify-content: space-between;
  }

  .helpModal {
    margin-left: 1rem;
    margin-top: 0.3rem;
  }

  &,
  * {
    cursor: pointer;
  }

  &__icon {
    @include display-flex {
      align-items: center;
      justify-content: center;
      width: 22px;
      height: 22px;
      color: map.get($color-list, 'orange', 700);
      border-radius: 4px;
      border: 1px solid map.get($color-list, 'orange', 500);
    }

    &__checked {
      border: none;
      background-color: map.get($color-list, 'orange', 400);
    }
  }

  &__label {
    @include body-text(2);
    color: map.get($color-list, 'neutral', 800);
    margin-left: 9px;
  }

  &__hovered__icon {
    background-color: map.get($color-list, 'orange', 300);

    &__checked {
      background-color: map.get($color-list, 'orange', 500);
    }
  }

  &__pressed__icon {
    background-color: map.get($color-list, 'orange', 400);

    &__checked {
      background-color: map.get($color-list, 'orange', 600);
    }
  }

  &__disabled {
    &,
    * {
      cursor: not-allowed;
    }

    &__icon {
      background-color: map.get($color-list, 'neutral', 200);
      border-color: map.get($color-list, 'neutral', 400);
      color: map.get($color-list, 'neutral', 400);

      &__checked {
        border-color: map.get($color-list, 'neutral', 400);
      }
    }

    &__label {
      color: map.get($color-list, 'neutral', 400);
    }
  }
}
