@use 'sass:map';
@use 'sass:math';
@import '/src/assets/scss/shared';

.ambassador_reward {
  position: relative;
  background-color: map.get($color-list, 'neutral', 200);
  border-radius: 2.4rem;
  padding: 3rem 2.4rem 4rem;
}

.title {
  margin-bottom: 2.4rem;
  @include subtitle-text;
  color: map.get($color-list, 'neutral', 800);

  &__dis {
    display: flex;
    justify-content: space-between;
  }
}
.helpModal {
  margin-left: 0.5rem;
  cursor: auto;
}

.row {
  @include display-flex;
}

.col {
  @include display-flex(column) {
    flex-basis: 0;
    flex-grow: 1;

    &:not(:last-child) {
      margin-right: 2.4rem;
    }
  }

  &--reversed {
    @include display-flex(column-reverse);
  }
}

.field {
  &__type {
    @include display-flex {
      position: relative;
      flex-basis: 0;
      flex-grow: 1;
      align-items: center;
      cursor: pointer;
      padding: 1.6rem;
      background-color: map.get($color-list, 'orange', 200);
      border-radius: 1.6rem;
      border: 1px solid transparent;
    }

    &:not(:last-child) {
      margin-right: 2.4rem;
    }

    &--selected {
      background-color: map.get($color-list, 'neutral', 100);
      border-color: map.get($color-list, 'orange', 500);
    }

    &--disabled {
      background-color: map.get($color-list, 'neutral', 200);
      border-color: map.get($color-list, 'neutral', 300);
    }

    &s__wrapper {
      @include display-flex {
        justify-content: space-between;
        margin-bottom: 2.4rem;
      }
    }

    &__text {
      @include subtitle-text(3) {
        color: map.get($color-list, 'neutral', 500);
      }

      &--selected {
        color: map.get($color-list, 'neutral', 800);
      }

      &--disabled {
        color: map.get($color-list, 'neutral', 400);
      }
    }

    &__icon {
      margin-left: auto;
    }
  }

  &__is_upload {
    @include display-flex {
      align-items: center;
      cursor: pointer;
      margin-bottom: 2.4rem;
    }

    &__text {
      @include body-text(2);
      color: map.get($color-list, 'neutral', 800);

      &--disabled {
        color: map.get($color-list, 'neutral', 400);
      }
    }
  }

  &__gap {
    height: 2.4rem;
    margin-bottom: 2.4rem;
  }

  &__image__top {
    @include display-flex {
      background-color: map.get($color-list, 'neutral', 100);
      padding: 8px 16px 8px 8px;
      border-radius: 16px;
      cursor: pointer;
      margin-bottom: 18px;
    }
  }

  &__image {
    @include display-flex {
      background-color: map.get($color-list, 'neutral', 100);
      padding: 0.8rem 1.6rem 0.8rem 0.8rem;
      border-radius: 1.6rem;
      cursor: pointer;
      margin-bottom: 0px;
    }

    &__preview {
      @include display-flex {
        position: relative;
        align-items: center;
        justify-content: center;
        margin-right: 1.6rem;
        width: 11rem;
        min-width: 11rem;
        height: 9.6rem;
        border-radius: 1.2rem;
        overflow: hidden;
      }

      &::before {
        content: '';
        position: absolute;
        width: 10.2rem;
        height: 8.8rem;
        left: 4px;
        top: 4px;
        border: 1px dashed map.get($color-list, 'orange', 300);
        border-radius: 1.2rem;
      }

      img {
        position: relative;
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover;
      }

      svg {
        width: 50%;
        height: auto;
      }
    }

    &__info {
      @include display-flex(column);

      &__filename {
        margin-top: 1.6rem;
        @include body-text;
        color: map.get($color-list, 'neutral', 800);
        max-width: 17rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        text-align: left;
      }

      &__text {
        margin-top: auto;

        @include button-text(2) {
          color: map.get($color-list, 'orange', 600);
          text-transform: uppercase;
        }

        &--disabled {
          color: map.get($color-list, 'neutral', 400);
        }
      }
      &__img_limit {
        font-size: 12px;
        color: map.get($color-list, 'orange', 600);
      }
    }
  }

  &__amount {
    position: relative;

    &__unit {
      position: absolute;
      left: 1.6rem;
      top: 3.5rem;
      z-index: 5;
      font-weight: 400;
      font-size: 1.3rem;
      line-height: 2rem;

      &--disabled {
        color: map.get($color-list, 'neutral', 400);
      }

      &--placeholder {
        color: map.get($color-list, 'neutral', 500);
      }
    }

    &__input {
      padding-left: 3.2rem !important;
    }
    &__input_promo {
      padding-left: 3.2rem !important;
      max-width: 15.2rem;
    }
  }

  &__promocode__input {
    max-width: 15.2rem;
  }
}

.button {
  &__close {
    @include display-flex {
      position: absolute;
      background-color: transparent;
      border: none;
      cursor: pointer;
      top: 2.4rem;
      right: 2.4rem;
      padding: 0;
    }
  }
}

.icon {
  &__check_box {
    @include display-flex {
      align-items: center;
      justify-content: center;
      width: 2.4rem;
      height: 2.4rem;
      border: 1px solid map.get($color-list, 'orange', 500);
      border-radius: 4px;
      color: map.get($color-list, 'orange', 700);
      margin-right: 1rem;
    }

    &--checked {
      border-color: map.get($color-list, 'orange', 400);
      background-color: map.get($color-list, 'orange', 400);
    }

    &--disabled {
      border-color: map.get($color-list, 'neutral', 400);
      background-color: map.get($color-list, 'neutral', 200);

      &.icon__check_box--checked {
        border-color: map.get($color-list, 'neutral', 300);
        background-color: map.get($color-list, 'neutral', 300);

        * {
          stroke: map.get($color-list, 'neutral', 400);
        }
      }
    }
  }
}
