@use 'sass:map';
@import '/src/assets/scss/shared';

.download_report {
  &__wrapper {
    overflow: visible;
    position: relative;
    margin-left: auto;
    margin-right: 10px;
  }

  &__icon {
    margin-left: 0.8rem;
    transform: rotate(180deg);

    * {
      stroke: map.get($color-list, 'orange', 700);
    }

    &--up {
      transform: rotate(0deg);
    }
  }

  &__menu {
    @include display-flex(column) {
      position: absolute;
      left: 0rem;
      top: 4.8rem;
      right: 0rem;
      z-index: 10;
      width: 100%;
      max-height: 0rem;
      overflow: hidden;
      background-color: $color-white;
      border-radius: 2.4rem;
      box-shadow: 0px 1px 8px -2px rgba(80, 85, 92, 0.03),
        0px 5px 10px 0.2px rgba(80, 85, 92, 0.03),
        0px 15px 20px 0.2px rgba(80, 85, 92, 0.05);
    }

    &--opened {
      max-height: 15rem;
    }

    &__item {
      @include body-text {
        padding: 0.8rem 2.4rem;
        text-align: right;
        color: map.get($color-list, 'orange', 700);
      }

      &:hover {
        background-color: map.get($color-list, 'orange', 200);
      }
    }
  }
}

.button {
  &__status {
    @include display-flex {
      align-items: center;
      justify-content: center;
      background-color: map.get($color-list, 'neutral', 100);
      border: 1px solid map.get($color-list, 'neutral,300');
      border-radius: 3rem;
      text-transform: capitalize;
      width: 10rem;
      height: 3.2rem;
      @include caption-text;
      color: map.get($color-list, 'neutral', 600);

      @include media-breakpoint-up('md') {
        width: 12.4rem;
        height: 4rem;
        @include subtitle-text(3);
      }
    }

    &--selected {
      background-color: map.get($color-list, 'orange', 400);
      border-color: map.get($color-list, 'orange', 400);
      color: map.get($color-list, 'orange', 700);
    }
  }
}
