@use 'sass:map';
@import '/src/assets/scss/shared';

.page {
  @include display-flex(column) {
    min-height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.header {
  @include display-flex {
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    background-color: rgba($color: #ffffff, $alpha: 0.95);
    backdrop-filter: blur(12px);
  }
  @include media-breakpoint-down('md') {
    display: none;
  }

  &__brand {
    @include display-flex {
      align-items: center;
      color: map.get($color-list, 'green', 500);
    }
    @include media-breakpoint-down('md') {
      display: none;
    }
  }

  &__logo {
    @include display-flex {
      position: relative;
      width: 4rem;
      height: 4rem;
      align-items: center;
      justify-content: center;
      background-color: map.get($color-list, 'green', 300);
      border-radius: 50%;
      overflow: hidden;
      margin-right: 12px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    span {
      @include subtitle-text;
      color: map.get($color-list, 'green', 600);
    }

    @include media-breakpoint-down('md') {
      display: none;
    }
  }

  &__visit {
    border: 1px solid map.get($color-list, 'green', 400);
    color: map.get($color-list, 'green', 500);

    &:hover {
      background-color: map.get($color-list, 'green', 100);
    }

    &:active {
      background-color: map.get($color-list, 'green', 200);
    }

    @include media-breakpoint-down('md') {
      display: none;
    }
  }
}

@mixin white-block {
  background-color: rgba($color: #ffffff, $alpha: 0.95);
  border-radius: 2.4rem;
}

.summary {
  display: grid;
  grid-template-columns: 1fr 10fr 10fr;
  @include white-block;
  height: 8rem;
  width: 77rem;
  max-width: 90vw;
  padding: 1rem 2rem;
  color: map.get($color-list, 'neutral', 800);

  @include media-breakpoint-down('md') {
    grid-template-columns: 1fr 3fr;
    width: 100%;
    max-width: 100%;
    height: min-content;
    border-radius: 0;
  }

  &__wrapper {
    margin: 2rem 0 2rem 0;
    @include media-breakpoint-down('md') {
      width: 100%;
      margin: 0 0;
    }
  }

  &__logo {
    height: 5.5rem;
    width: 5.5rem;

    &_img {
      height: 5.5rem;
      width: 5.5rem;
      clip-path: circle(50%);
      object-fit: contain;
    }
    @include media-breakpoint-down('md') {
      // display: none;
    }
  }

  &__person {
    display: grid;
    margin: 1rem;

    @include media-breakpoint-down('md') {
      flex: 0 0;
      margin: 1rem 0;
    }

    &_name {
      font-weight: 800;
      font-size: 1.4rem;
    }

    &_email {
      color: map.get($color-list, 'orange', 700);
    }
  }

  &__data_wrapper {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    @include media-breakpoint-down('md') {
      grid-column-start: 1;
      grid-column-end: 3;
      border-top: 2px solid map.get($color-list, 'neutral', 300);
    }
  }

  &__data {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem;
    flex: 0 7rem;

    @include media-breakpoint-down('md') {
      justify-content: space-between;
      flex: 0 0;
      margin: 1rem 0 1rem 0;
    }

    &_label {
      color: map.get($color-list, 'neutral', 600);
      white-space: nowrap;
    }
  }
}

.main {
  @include white-block;

  @include display-flex(column) {
    border-radius: 2.4rem;
    height: fit-content;
    width: 61rem;
    padding: 40px 142px 34px;

    @include media-breakpoint-down('md') {
      width: 100%;
      padding: 1rem;
      border-radius: 0;
    }

    &--joined {
      width: 77rem;
      max-width: 90vw;
      padding: 30px 118px 48px;
      @include media-breakpoint-down('md') {
        width: 100%;
        max-width: 100%;
        padding: 0;
      }
    }
  }

  &__wrapper {
    @include display-flex {
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      flex-basis: 0;
      flex-grow: 1;
    }
  }

  &__terms {
    margin: 0 0 2.4rem 0;
    text-decoration: underline;
    color: map.get($color-list, 'green', 500);
  }
}

.ambassadorSummary {
  @include white-block;
}

.mobile_intro {
  width: 100%;
  color: map.get($color-list, 'orange', 500);
  font-weight: 100;
  text-align: center;
  background-color: map.get($color-list, 'orange', 100);

  @include media-breakpoint-up('sm') {
    display: none;
  }

  &__image {
    height: 15rem;
    width: 100%;
    background-position: center;
    background-size: cover;
  }
}

.intro {
  background-color: map.get($color-list, 'neutral', 200);
  margin-bottom: 4rem;
  text-align: center;
  padding: 1rem;

  @include media-breakpoint-down('md') {
    // display: none;
    margin-bottom: 0;
    padding: 0rem 0 2rem 0;
  }

  &__title {
    font-size: 2rem;
    font-weight: 100;
    margin-bottom: 2rem;
    @include media-breakpoint-down('md') {
      font-size: 1.7rem;
      margin: 2rem 0 0 0;
    }
  }

  &__paragraph {
    @include media-breakpoint-down('md') {
      font-size: 1rem;
    }
  }
}

.rewards {
  display: flex;
  margin-bottom: 3rem;
  justify-content: center;

  @include media-breakpoint-down('md') {
    flex-direction: column;
    padding: 0 0 2.4rem;
    background-color: map.get($color-list, 'neutral', 200);
    margin-bottom: 0;
  }
}

.reward {
  position: relative;
  @include white-block;
  text-align: center;
  width: 18rem;
  height: 14rem;
  @include box-shadow;

  display: flex;
  flex-direction: column;
  align-items: center;

  @include media-breakpoint-down('md') {
    text-align: center;
    display: grid;
    grid-template-columns: 1fr 4fr;
    width: 85%;
    height: auto;
    border-radius: 3px;
    margin: 1rem 2.4rem 0 2.4rem;
  }

  &__img {
    position: relative;
    width: 5rem;
    height: 5rem;
    top: -50%;
    transform: translateY(5rem);
    object-fit: cover;
    @include media-breakpoint-down('md') {
      width: 4rem;
      height: 4rem;
      grid-column: 1 / 2;
      grid-row: 1 / 3;
      top: 0;
      transform: translateY(0rem);
      object-fit: cover;
      margin-left: 1rem;
    }
  }

  &__type {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    font-size: 1.2rem;
    color: map.get($color-list, 'neutral', 800);
    @include media-breakpoint-down('md') {
      display: block;
    }
  }

  &__description {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    color: map.get($color-list, 'neutral', 800);
    font-size: 1.2rem;
    margin-top: 1.5rem;
    @include media-breakpoint-down('md') {
      display: block;
      margin-top: 0;
    }
  }

  &:not(:last-child) {
    margin-right: 1rem;
  }
}

.info {
  @include display-flex(column) {
    align-items: center;
  }

  &__logo {
    @include media-breakpoint-down('md') {
      display: none;
    }

    @include display-flex {
      position: relative;
      width: 80px;
      height: 80px;
      align-items: center;
      justify-content: center;
      background-color: map.get($color-list, 'green', 300);
      border-radius: 50%;
      overflow: hidden;
      margin-right: 12px;
      margin-bottom: 24px;
    }

    img {
      width: 100%;
      height: 100%;
    }

    span {
      font-family: 'Rubik-Medium';
      font-weight: 500;
      font-size: 30px;
      line-height: 40px;
      color: map.get($color-list, 'green', 600);
    }
  }

  &__title {
    text-align: center;
    color: map.get($color-list, 'neutral', 800);
    margin-bottom: 2rem;
    @include media-breakpoint-down('md') {
      margin-bottom: 1rem;
    }
  }

  &__desc {
    text-align: center;
    color: map.get($color-list, 'neutral', 600);
    margin-bottom: 2.5rem;
    @include media-breakpoint-down('md') {
      margin-bottom: 1rem;
    }
  }
}

.split_line {
  border-top: 1px solid map.get($color-list, 'neutral', 300);
  margin-bottom: 24px;
}

.form {
  margin-bottom: 48px;

  &__legend {
    text-align: center;
    color: map.get($color-list, 'neutral', 600);
    margin-bottom: 24px;
  }

  &__row {
    @include display-flex;
    @include media-breakpoint-down('md') {
      flex-direction: column;
    }
  }

  &__col {
    @include display-flex(column) {
      flex-basis: 0;
      flex-grow: 1;

      &:not(:last-child) {
        margin-right: 24px;
      }
    }
  }

  &__submit {
    &__label {
      visibility: hidden;
    }

    &__wrapper {
      @include display-flex;
      @include media-breakpoint-down('md') {
        justify-content: center;
      }
    }
  }

  &__accept_terms {
    @include display-flex {
      align-items: center;
      cursor: pointer;
      margin-top: 20px;
    }

    &__checkbox--error span {
      border-color: map.get($color-list, 'red', 500);
    }

    & > span {
      margin-left: 8px;
      @include body-text(2);
      color: map.get($color-list, 'neutral', 800);

      button {
        background-color: transparent;
        padding: 0px;
        border: none;
        cursor: pointer;
        @include body-hyperlink-text(2);
        color: map.get($color-list, 'green', 500);
      }
    }
  }
}

.powered_by {
  @include display-flex {
    align-items: center;
    justify-content: center;
  }

  @include subtitle-text(3) {
    color: map.get($color-list, 'neutral', 500);
  }

  &__logo {
    margin-left: 8px;
    width: auto;
    height: 20px;
  }
}

.share {
  @include display-flex(column) {
    justify-content: space-between;
    width: 100%;
    margin-bottom: 2.4rem;
  }
  @include media-breakpoint-down('md') {
    flex-direction: column;
  }

  &__wrapper {
    @include display-flex(column) {
      align-items: center;
      background-color: map.get($color-list, 'orange', 200);
      border-radius: 24px;
      backdrop-filter: blur(12px);
      padding: 2.3em 11.8rem 2.4rem;
    }
    @include media-breakpoint-down('md') {
      margin: 2.4rem 2rem 2.4rem 2rem;
      padding: 1rem 1rem;
      padding-top: 0;
      padding: 2.4rem 1.6rem 2.4rem 1.6rem;
      border-radius: 3px;
      @include box-shadow;
      background-color: map.get($color-list, 'orange', 100);
    }
  }

  &__title {
    @include subtitle-text(1) {
      color: map.get($color-list, 'neutral', 800);
      margin-bottom: 12px;
      @include media-breakpoint-down('md') {
        margin-top: 1rem;
        margin-bottom: 0;
      }
    }
  }

  &__desc {
    @include body-text(3) {
      color: map.get($color-list, 'neutral', 600);
      margin-bottom: 18px;
      @include media-breakpoint-down('md') {
        text-align: center;
      }
    }
  }

  &__input {
    margin-right: 24px;
  }

  &__buttons {
    width: 75%;
    display: flex;
    justify-content: space-evenly;
  }
}
