@use "sass:map";
@import "/src/assets/scss/shared";

.modal {
  @include media-breakpoint-down("sm") {
    width: 100%;
    height: 100%;
    border-radius: 0px;
    padding: 164px 24px 24px;
  }
}

.title {
  color: map.get($color-list, "neutral", 800);
  margin-bottom: 16px;
  text-align: center;
}

.body {
  color: map.get($color-list, "neutral", 800);
  margin-bottom: 32px;
  text-align: center;
}

.close {
  position: absolute;
  background-color: transparent;
  padding: 0px;
  border: none;
  cursor: pointer;
  top: 16px;
  right: 16px;
}

.buttons {
  @include display-flex {
    align-items: center;
    justify-content: center;
  }

  .button {
    &__cancel {
      width: 150px;
      margin-right: 24px;

      @include media-breakpoint-up("xxl") {
        width: 110px;
      }
    }

    &__import {
      width: 150px;

      @include media-breakpoint-up("xxl") {
        width: 110px;
      }
    }
  }
}

.table {
  width: 100%;
  margin-bottom: 32px;

  thead th {
    @include body-text(2) {
      color: map.get($color-list, "neutral", 500);
      text-align: left;
      border-bottom: 1px solid map.get($color-list, "neutral", 300);
    }
  }

  tbody {
    tr {
      td {
        color: map.get($color-list, "neutral", 800);
        padding: 4px 0px;
      }

      &:not(:last-child) td {
        border-bottom: 1px solid map.get($color-list, "neutral", 300);
      }
    }
  }
}
