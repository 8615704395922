@use 'sass:map';
@import '/src/assets/scss/shared';

.date_range {
  @include display-flex(column) {
    position: relative;
    margin-bottom: 24px;
  }

  &__dis {
    display: flex;
    justify-content: space-between;
  }
  &__button {
    @include display-flex {
      align-items: center;
      padding: 10px 16px;
      border-radius: 24px;
      background-color: $color-white;
      border: 1px solid map.get($color-list, 'neutral', 400);
      outline: none;
      width: 100%;
      height: 48px;
      cursor: pointer;

      &__active {
        color: map.get($color-list, 'neutral', 500);
      }
    }

    @include body-text(3);
    color: map.get($color-list, 'neutral', 800);

    @include media-breakpoint-up('xxl') {
      height: 40px;
    }

    &:active,
    &:focus {
      border-color: map.get($color-list, 'neutral', 800);
    }

    &:disabled {
      cursor: unset;
      color: map.get($color-list, 'neutral', 400);
      border-color: map.get($color-list, 'neutral', 300);
    }

    &--error {
      border-color: map.get($color-list, 'red', 500);
    }
  }

  &__label {
    @include subtitle-text(2);
    color: map.get($color-list, 'neutral', 800);
    text-indent: 8px;
  }

  &__required {
    color: map.get($color-list, 'green', 500);
  }

  &__optional {
    @include body-text(2) {
      color: map.get($color-list, 'neutral', 600);
    }
  }
}

.icon__calendar {
  margin-right: 16px;
}

.caption {
  position: absolute;
  width: 100%;
  text-indent: 8px;
  bottom: -20px;
  @include caption-text(2) {
    color: map.get($color-list, 'neutral', 500);
  }

  &__error {
    color: map.get($color-list, 'red', 500);
  }
}

.date_picker {
  @include display-flex {
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 10;
    left: 0;
    right: 0;
  }
}
