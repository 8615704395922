@use 'sass:map';
@import '/src/assets/scss/shared';

.page {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  &__background {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: -1000;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    @include media-breakpoint-down('md') {
      display: none;
    }
  }
}

.header {
  position: relative;
  display: flex;
  min-height: 8rem;
  align-items: center;
  justify-content: space-between;
  padding: 1.6rem 2.4rem;
  background-color: rgba($color: #ffffff, $alpha: 0.95);
  // background-color: rgba($color: pink, $alpha: 0.95);

  @include media-breakpoint-down('md') {
    flex-direction: column;
    background-color: rgba($color: #ffffff, $alpha: 0);
    min-height: 23rem;
    padding: 0;
  }

  &__img {
    height: 100%;
    width: 100%;
    padding: 0;
    @include media-breakpoint-up('md') {
      display: none;
    }
  }

  &__brand {
    position: relative;
    display: flex;
    align-items: center;
    color: map.get($color-list, 'green', 500);
  }

  &__logo {
    position: relative;
    width: 8rem;
    height: 8rem;
    align-items: center;
    justify-content: center;
    background-color: map.get($color-list, 'green', 300);
    border-radius: 50%;
    overflow: hidden;
    margin-right: 1.2rem;

    @include media-breakpoint-down('md') {
      position: relative;
      transform: translate(0, -50%);
      margin-right: 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    span {
      @include subtitle-text;
      color: map.get($color-list, 'green', 600);
    }
  }

  &__camp_name {
    @include media-breakpoint-down('md') {
      display: none;
    }
  }

  &__visit {
    border: 1px solid map.get($color-list, 'green', 400);
    color: map.get($color-list, 'green', 500);

    &:hover {
      background-color: map.get($color-list, 'green', 100);
      @include media-breakpoint-down('md') {
        background-color: #ffffff00;
        font-weight: bold;
      }
    }

    &:active {
      background-color: map.get($color-list, 'green', 200);
    }

    @include media-breakpoint-down('md') {
      position: absolute;
      bottom: 0;
      border: none;
      text-decoration: underline;
      font-size: 1.2rem;
      text-transform: none;
    }
  }
}

.info {
  position: relative;
  display: flex;
  align-items: center;
  padding: 2.4rem 3.2rem;
  background: rgba(#ffffff, 0.9);
  backdrop-filter: blur(1.2rem);
  border-radius: $border-radius;
  margin-bottom: 2.4rem;
  max-width: 1200px;
  @include media-breakpoint-down('md') {
    margin-bottom: 0;
    padding-top: 0;
    text-align: center;
    justify-content: center;
  }

  &__logo {
    position: relative;
    width: 8rem;
    height: 8rem;
    align-items: center;
    background-color: map.get($color-list, 'green', 300);
    border-radius: 50%;
    overflow: hidden;
    margin-right: 3.2rem;

    @include media-breakpoint-down('md') {
      display: none;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    span {
      font-family: 'Rubik-Medium';
      font-weight: 500;
      font-size: 3rem;
      line-height: 4rem;
      color: map.get($color-list, 'green', 600);
    }
  }

  &__text {
    h5 {
      margin-bottom: 0.8rem;
      color: map.get($color-list, 'neutral', 800);
    }

    p {
      color: map.get($color-list, 'neutral', 600);
      max-width: 1000px;
    }
  }
}

.main {
  &__wrapper {
    display: flex;
    justify-content: center;
    flex-basis: 0;
    flex-grow: 1;
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
    @include media-breakpoint-down('md') {
      flex-direction: column;
      padding-top: 0;
      flex-basis: auto;
    }
  }

  &__inner {
    display: flex;
    @include media-breakpoint-down('md') {
      display: flex;
      flex-direction: column;
    }
  }
}

.form {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-basis: 0;
  flex-grow: 1;
  width: 44.8rem;
  border-radius: $border-radius;
  margin-left: 1.2rem;
  padding: 3.2rem 3.2rem;
  @include media-breakpoint-down('md') {
    width: 90vw;
  }

  &__arrow {
    position: absolute;
    right: 1.6rem;
    top: 1.6rem;
    cursor: pointer;
    @include media-breakpoint-up('md') {
      display: none;
    }

    &--down {
      transform: rotate(180deg);
    }
  }

  &--orange {
    background-color: map.get($color-list, 'orange', 100);
    margin-left: 0px;
  }

  &--green {
    background-color: map.get($color-list, 'green', 100);
  }

  &__texts {
    margin-bottom: auto;
  }

  &__title {
    @include subtitle-text;
    margin-bottom: 0.8rem;

    &--orange {
      color: map.get($color-list, 'orange', 500);
    }

    &--green {
      color: map.get($color-list, 'green', 500);
    }
  }

  &__desc {
    @include body-text(2);
    color: map.get($color-list, 'neutral', 600);
  }

  &__fieldset {
    padding: 0px;
    border: none;
    margin: 1.6rem 0;
    @include media-breakpoint-down('md') {
      // display: none;
    }
  }

  &__fieldset--hidden {
    display: none;
  }

  &__button {
    @include media-breakpoint-down('md') {
      width: 75vw;
    }
    &--green {
      background-color: map.get($color-list, 'green', 300);
      border: 1px solid map.get($color-list, 'green', 300);
      padding: 0.7rem 1.4rem;
      color: map.get($color-list, 'green', 600);

      &:hover {
        background-color: map.get($color-list, 'green', 100);
      }

      &:active {
        background-color: map.get($color-list, 'green', 200);
      }
    }

    &__wrapper {
      display: flex;

      @include media-breakpoint-down('md') {
        margin-top: 1.6rem;
      }
    }
  }
}

.powered_by {
  @include display-flex {
    align-items: center;
    justify-content: center;
    background-color: map.get($color-list, 'neutral', 200);
    padding-top: 1rem;
    padding-bottom: 1.4rem;
  }

  @include subtitle-text(3) {
    color: map.get($color-list, 'neutral', 500);
  }

  &__logo {
    margin-left: 0.8rem;
    width: auto;
    height: 2rem;
  }
}
