@use "sass:map";
@import "/src/assets/scss/shared";

.primary_button {
  @include display-flex {
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border-radius: 24px;
    cursor: pointer;
    padding: 12px 16px;
    height: 48px;
  }

  @include button-text {
    color: map.get($color-list, "orange", 600);
    text-transform: uppercase;
  }

  @include media-breakpoint-up("xxl") {
    height: 40px;
    padding: 8px 16px;
    @include button-text(2);
  }

  &__hovered {
    background-color: map.get($color-list, "orange", 100);
  }

  &__pressed {
    background-color: map.get($color-list, "orange", 200);
  }

  &__disabled {
    color: map.get($color-list, "neutral", 500);
    cursor: not-allowed;
  }
}
