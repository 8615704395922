@use "sass:map";
@import "/src/assets/scss/shared";

.modal {
  width: 800px;
  padding: 48px 92px 64px;
  @include media-breakpoint-down('md') {
    padding: 1rem;
    width: 100%;
  }


  * {
    transition-duration: unset;
  }

  &__content {
    @include display-flex(column) {
      height: 100%;
      align-items: center;
    }
  }
  p:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.title {
  color: map.get($color-list, "neutral", 800);
  margin-bottom: 64px;
  text-align: center;
  @include media-breakpoint-down('md') {
    margin-bottom: 1rem;
  }
}

.terms {
  width: 100%;
  @include media-breakpoint-down('md') {
    margin-top: 0;
  }
}

.close {
  position: absolute;
  background-color: transparent;
  padding: 0px;
  border: none;
  cursor: pointer;
  top: 16px;
  right: 16px;
}
