@use "sass:math";
@import "/src/assets/scss/shared";

.color_picker__wrapper {
  .react-colorful {
    width: 100%;
    height: 360px;
    display: inherit;
    flex-direction: unset;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 8px;

    @include media-breakpoint-up("xxl") {
      width: 210px;
      height: 230px;
    }

    &__pointer {
      width: 14px;
      height: 14px;
    }

    &__saturation {
      height: 330px;
      border: none;
      border-radius: 12px;
      margin-bottom: 16px;

      @include media-breakpoint-up("xxl") {
        height: 210px;
      }

      &-pointer {
        width: 16px;
        height: 16px;
      }
    }

    &__last-control {
      border-radius: 12px;
      height: 16px;
    }
  }
}
