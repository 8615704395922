@use "sass:map";
@import "/src/assets/scss/shared";

.text_input {
  padding: 10px 16px;
  border-radius: 24px;
  background-color: $color-white;
  border: 1px solid map.get($color-list, "neutral", 400);
  outline: none;
  height: 48px;
  @include body-text(3);
  color: map.get($color-list, "neutral", 800);

  @include media-breakpoint-up("xxl") {
    height: 40px;
  }

  &__wrapper {
    width: 100%;

    @include display-flex(column) {
      position: relative;
      margin-bottom: 24px;
    }
  }

  &::placeholder {
    color: map.get($color-list, "neutral", 500);
  }

  &:active,
  &:focus {
    border-color: map.get($color-list, "neutral", 800);
  }

  &:disabled {
    color: map.get($color-list, "neutral", 400);
    border-color: map.get($color-list, "neutral", 300);
  }

  &--error {
    border-color: map.get($color-list, "red", 500);
  }

  &__label {
    @include subtitle-text(2);
    color: map.get($color-list, "neutral", 800);
    text-indent: 8px;
  }

  &__icon {
    position: absolute;
    right: 7px;
    bottom: 8px;
    cursor: pointer;
  }
}

.caption {
  position: absolute;
  width: 100%;
  text-indent: 8px;
  bottom: -20px;
  @include caption-text(2) {
    color: map.get($color-list, "neutral", 500);
  }

  &__error {
    color: map.get($color-list, "red", 500);
  }
}
