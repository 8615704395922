@use 'sass:map';
@import '/src/assets/scss/shared';

.page {
  margin: 32px 40px;
}

.helpModal {
  margin-left: 1rem;
  margin-top: 0.2rem;
}

.accordians {
  margin: 32px 0px;
}
.accordion_section {
  margin: 0px 0rem 1.6rem;

  &__subtitle {
    margin: 1.6rem 0;
    @include subtitle-text;
    color: map.get($color-list, 'neutral', 800);
  }
}

.wrapper {
  @include display-flex(column) {
    align-items: center;
    justify-content: center;
    background-color: map.get($color-list, 'orange', 100);
    border-radius: 24px;
    padding-top: 38px;
    padding-bottom: 44px;
    margin-bottom: 24px;
  }
}

.title {
  @include subtitle-text {
    color: map.get($color-list, 'neutral', 800);
    margin-bottom: 26px;
    font-size: 2rem;
    @include display-flex;
  }
}
.accordian_title {
  color: #976821;
}
.link {
  color: #976821;
  cursor: pointer;
  font-style: italic;
}
