@use "sass:map";
@use "sass:math";
@import "/src/assets/scss/shared";

.section {
  width: 100%;
}

.subtitle {
  margin: 16px 0px;
  @include subtitle-text;
  color: map.get($color-list, "neutral", 800);
}

.form {
  &__row {
    @include display-flex {
      justify-content: space-between;
    }
  }

  &__col {
    @include display-flex(column) {
      flex-basis: 0;
      flex-grow: 1;

      &:not(:last-child) {
        margin-right: 24px;
      }
    }
  }
}

.field__merge_tag {
  @include display-flex {
    @include caption-text;
    padding: 4px 8px;
    border-radius: 12px;
    margin-top: 4px;
    margin-bottom: 4px;

    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  &s__wrapper {
    @include display-flex {
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 12px;
    }
  }

  &__text {
    @include caption-text {
      color: map.get($color-list, "orange", 700);
      margin-right: 16px;
    }
  }
  &__text_green {
    @include caption-text {
      color: map.get($color-list, "green", 600);
      margin-right: 16px;
    }
  }

  &--required {
    color: map.get($color-list, "orange", 700);
    background-color: map.get($color-list, "orange", 200);
  }

  &--optional {
    color: map.get($color-list, "green", 600);
    background-color: map.get($color-list, "green", 200);
  }
}
