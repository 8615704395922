@use 'sass:map';
@import '/src/assets/scss/shared';

.section {
  @include display-flex(column) {
    justify-content: space-between;
    height: 100%;
  }

  &__title {
    color: map.get($color-list, 'green', 500);
    padding: 34px 52px 34px 8px;
  }

  .profile_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .helpModal {
    margin-left: 16px;
    margin-top: 8px;
    &__top {
      margin-right: 24px;
    }
    &__metrics {
      margin-left: 10px;
      margin-top: 4px;
    }
    &__report {
      margin-right: 10px;
    }
    &__header {
      margin-left: 10px;
      margin-top: 3px;
    }
  }
  &__header {
    @include display-flex;
    margin-bottom: 24px;
  }

  &__main {
    @include display-flex(column) {
      background-color: $color-white;
      border-radius: 24px;
      padding: 24px;
    }
  }

  &__footer {
    @include display-flex {
      align-items: center;
      justify-content: center;
      height: 100px;
      padding: 24px 0px 36px;
      margin-top: auto;
    }
  }

  &__referral {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
  }
  &__referral_date {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.reward {
  @include display-flex {
    background-color: $color-white;
    border-radius: 24px;
    padding: 8px;
    width: 330px;
    margin-right: 24px;
  }

  &__img {
    width: 100px;
    height: 88px;
    margin-right: 16px;
    border-radius: 12px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__texts {
    @include display-flex(column) {
      padding-top: 8px;
      width: 165px;
    }
  }

  &__for {
    @include subtitle-text {
      &--ambassador {
        color: map.get($color-list, 'red', 400);
      }

      &--prospect {
        color: map.get($color-list, 'blue', 400);
      }
    }
    margin-bottom: 8px;
  }

  &__price {
    color: map.get($color-list, 'neutral', 800);
  }
}

.metrics {
  &__header {
    @include display-flex {
      align-items: center;
      margin-bottom: 32px;
    }
  }

  &__title {
    @include subtitle-text;
    color: map.get($color-list, 'neutral', 800);
    margin-right: auto;
    @include display-flex;
  }

  &__title_2 {
    @include subtitle-text;
    color: map.get($color-list, 'neutral', 800);
    margin-right: auto;
    margin-bottom: 30px;
    @include display-flex;
  }

  &__th {
    @include body-text(2) {
      text-align: left;
      color: map.get($color-list, 'neutral', 500);
      margin-left: auto;
      margin-right: auto;
      display: flex;
    }
  }

  &__tr:first-child td {
    padding-top: 16px;
  }
}

.referral {
  td {
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid map.get($color-list, 'neutral', 300);
    @include body-text(3);
  }

  &__ambassador_name,
  &__prospect {
    color: map.get($color-list, 'orange', 700);
    text-decoration: underline;
  }

  &__p_email {
    color: map.get($color-list, 'blue', 400);
    text-decoration: underline;
  }

  &__status {
    border-radius: 12px;
    padding: 4px 8px;
    background-color: map.get($color-list, 'red', 200);
    line-height: 3rem !important;

    @include caption-text {
      color: map.get($color-list, 'red', 500);
    }

    &--completed {
      background-color: map.get($color-list, 'green', 200);
      color: map.get($color-list, 'green', 600);
    }
    &--completed-referral {
      background-color: map.get($color-list, 'green', 200);
      color: map.get($color-list, 'green', 600);
      line-height: 1.5rem !important;
      margin-right: 10px;
    }
  }

  &__action {
    position: relative;
  }
}

.button {
  &__icon {
    margin-right: 8px;
  }

  &__back,
  &__next {
    margin-right: 16px;
  }
  &__save {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    height: 35px;
  }

  &__download {
    margin-left: auto;
    margin-right: 50px;
    display: none;
  }

  &__download_csv {
    margin-left: auto;
    margin-right: 10px;
  }

  &__upload {
    margin-right: 24px;
    display: none;
  }

  &__action {
    @include display-flex {
    }

    &__icon {
      transform: rotate(90deg);
    }
  }

  &__approve {
    padding: 4px 12px;
    width: 80px;
    height: 30px;
    letter-spacing: unset;
    text-transform: unset;
  }
  &__danger {
    padding: 4px 12px;
    width: 80px;
    height: 30px;
    letter-spacing: unset;
    text-transform: unset;
    background-color: #f6d4d4;
    color: #d22827;
  }
  &__danger:hover {
    background-color: #f6d4d4a9;
  }
}

.action_menu {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 24px;
  background-color: #fff;
  width: 280px;
  max-height: 0px;
  overflow: hidden;
  z-index: 10;
  @include box-shadow;

  &--opened {
    max-height: 300px;
  }

  &__item {
    @include display-flex {
      align-items: center;
      padding: 8px 16px;
      color: map.get($color-list, 'neutral', 800);
    }

    span {
      margin-right: auto;
    }

    &--delete {
      cursor: pointer;

      &:hover {
        color: map.get($color-list, 'red', 500);
        background-color: map.get($color-list, 'red', 100);
      }
    }
  }
}

.action_menu_paid {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 24px;
  background-color: #fff;
  width: 150px;
  max-height: 0px;
  display: none;
  z-index: 10;
  @include box-shadow;

  &--opened {
    max-height: 400px;
    display: block;
  }

  &__item {
    @include display-flex {
      align-items: center;
      padding: 8px 16px;
      color: map.get($color-list, 'neutral', 800);
    }

    span {
      margin-right: auto;
    }

    &--delete {
      cursor: pointer;

      &:hover {
        color: map.get($color-list, 'red', 500);
        background-color: map.get($color-list, 'red', 100);
      }
    }
  }
}

.action_menu:last-of-type,
.action_menu_paid:last-of-type {
  top: -50px;
}

.input__registrations {
  box-sizing: border-box;
  width: 50px;
  height: 30px;
  border-radius: 15px;
  padding-left: 12px;
  border: 1px solid map.get($color-list, 'neutral', 400);
  outline: unset;
  margin-right: 8px;
}

.input__paid {
  box-sizing: border-box;
  width: 100px;
  height: 30px;
  border-radius: 15px;
  padding-left: 12px;
  border: 1px solid map.get($color-list, 'neutral', 400);
  outline: unset;
  margin-bottom: 10px;
}

.amount_paid {
  width: 100px;
  height: 30px;
}
.amount_paid_container {
  margin-bottom: 10px !important;
}
