@use 'sass:map';
@import '/src/assets/scss/shared';

.section {
  position: relative;
  background-color: map.get($color-list, 'neutral', 200);
  border-radius: 24px;
  padding: 30px 24px;

  &__header {
    @include display-flex {
      align-items: center;
    }
  }

  .helpModal {
    margin-left: 1rem;
  }

  &__title {
    @include subtitle-text;
    color: map.get($color-list, 'neutral', 800);
    margin-right: 16px;
  }

  &__description {
    @include body-text(2) {
      color: map.get($color-list, 'neutral', 500);
    }
  }

  &__close {
    background-color: transparent;
    padding: 0px;
    border: none;
    cursor: pointer;
    margin-left: auto;
  }

  &__content {
    max-height: 0px;
    overflow: auto;

    &--opened {
      margin-top: 16px;

      @for $scale from 1 to 10 {
        &--#{$scale}x {
          max-height: calc(100vh * #{$scale});
        }
      }
    }
  }
}

.icon {
  transform: rotate(180deg);

  &--opened {
    transform: rotate(0deg);
  }
}
