@use 'sass:map';
@import '/src/assets/scss/shared';

.modal {
  @include media-breakpoint-up('md') {
    width: 600px;
    max-height: 90vh;
  }

  @include media-breakpoint-up('xl') {
    width: 800px;
    padding: 50px 60px;
  }
}
.title {
  margin-bottom: 16px;
  text-align: center;
}

.body {
  margin-bottom: 32px;
  text-align: center;
}

.close {
  position: absolute;
  background-color: transparent;
  padding: 0px;
  border: none;
  cursor: pointer;
  top: 16px;
  right: 16px;
}
