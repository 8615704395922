@use 'sass:map';
@use 'sass:math';
@import '/src/assets/scss/shared';

.section {
  @include display-flex(column) {
    min-width: 42rem;
    max-width: 92rem;
  }

  .profile_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .helpModal {
    margin-left: auto;
    margin-bottom: 4.8rem;
  }
  .tags {
    display: flex;
    flex-direction: row;
    background: white;
    padding: 10px;
    border-radius: 10px;
    margin: 10px 0px;
    &__optional {
      color: #45863a;
      font-size: 12px;
      margin-right: 10px;
      &__tag {
        margin-bottom: 0px !important;
      }
    }
  }

  &__title {
    color: map.get($color-list, 'green', 500);
    margin-bottom: 48px;
  }

  &__description {
    margin-left: 24px;

    @include body-text(2) {
      color: map.get($color-list, 'neutral', 500);
    }
  }

  &__footer {
    @include display-flex {
      align-items: center;
      justify-content: center;
      height: 100px;
    }
  }
}

.form {
  fieldset {
    border: none;
    background-color: map.get($color-list, 'neutral', 100);
    border-radius: 24px;
    padding: 32px 96px 52px;
    margin-bottom: 24px;
  }

  &__small_ta {
    height: 5rem;
  }

  &__row {
    @include display-flex {
      justify-content: space-between;
    }
  }
  &__row_space {
    @include display-flex {
      justify-content: space-between;
    }
    margin-top: 16px;
  }
  &__row--heading {
    margin: 2.4rem 0;
    margin-right: 16px;
    font-size: 1.5rem;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2.4rem;
    letter-spacing: 0.25px;
    color: map.get($color-list, 'neutral', 800);
    margin-bottom: 16px;
  }

  &__col {
    @include display-flex(column) {
      flex-basis: 0;
      flex-grow: 1;

      &:not(:last-child) {
        margin-right: 24px;
      }
    }
  }
}

.button {
  &__email {
    &__icon {
      margin-right: 8px;

      &--disabled * {
        fill: map.get($color-list, 'neutral', 200);
        stroke: map.get($color-list, 'neutral', 300);
      }
    }
  }

  &__back,
  &__next {
    margin-right: 16px;
  }

  &__uploadBgImg {
    &__icon {
      margin-right: 8px;

      &--disabled * {
        fill: map.get($color-list, 'neutral', 200);
        stroke: map.get($color-list, 'neutral', 400);
      }
    }
  }
}

.field {
  &__bgImg {
    @include display-flex(column) {
      position: absolute;
      align-items: center;
    }

    &--change {
      right: 16px;
      bottom: 16px;
    }

    &__wrapper {
      @include display-flex {
        position: relative;
        flex-basis: 0;
        flex-grow: 1;
        align-items: center;
        justify-content: center;
        margin-bottom: 16px;
      }
    }

    &__preview {
      @include display-flex {
        position: relative;
        flex-basis: 0;
        flex-grow: 1;
        align-items: center;
        justify-content: center;
        height: 224px;
        background-color: map.get($color-list, 'neutral', 100);
        border: 1px solid transparent;
        border-radius: 16px;
        overflow: hidden;
      }

      &--error {
        border-color: map.get($color-list, 'red', 500);
        background-color: map.get($color-list, 'red', 100);
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    &__placeholder {
      margin: 12px auto;
      width: auto;
      height: 200px;
    }

    &__text {
      margin-bottom: 4px;
      @include subtitle-text;
      color: map.get($color-list, 'neutral', 800);

      &__desc {
        @include caption-text {
          color: map.get($color-list, 'neutral', 500);
          margin-bottom: 24px;
        }
      }
    }
  }

  &__offerToAnswer {
    padding: 8px 0px;
  }

  &__merge_tag {
    @include display-flex {
      @include caption-text;
      padding: 4px 8px;
      border-radius: 12px;

      &:not(:last-child) {
        margin-right: 8px;
        margin-bottom: 8px;
      }
    }

    &s__wrapper {
      @include display-flex {
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 12px;
      }
    }

    &__text {
      @include caption-text {
        color: map.get($color-list, 'orange', 700);
        margin-right: 16px;
      }
    }

    &--required {
      color: map.get($color-list, 'orange', 700);
      background-color: map.get($color-list, 'orange', 200);
    }

    &--optional {
      color: map.get($color-list, 'green', 600);
      background-color: map.get($color-list, 'green', 200);
    }
  }

  &__email_to {
    @include display-flex {
      padding: 8px 0px;
      width: 100%;
      border-bottom: 1px solid map.get($color-list, 'neutral', 300);
    }

    &__checkbox {
      margin-right: auto;
    }
  }
}

.accordion_section {
  margin: 0px -24px 16px;

  &__subtitle {
    margin: 16px 0px;
    @include subtitle-text;
    color: map.get($color-list, 'neutral', 800);
  }
}

.label__spacing {
  height: 24px;
}

.split_line {
  border: 1px solid map.get($color-list, 'neutral', 300);
  margin: 32px 0px;
}

.landing {
  &__preview {
    &__container {
      @include display-flex(column);

      label {
        visibility: hidden;
        @include subtitle-text(2);
        color: map.get($color-list, 'neutral', 800);
        text-indent: 8px;
      }
    }

    &__button {
      margin-left: auto;

      &__wrapper {
        @include display-flex;
      }
    }
  }
}

.emails_to {
  &__wrapper {
    @include display-flex(column) {
      &:not(:last-child) {
        margin-bottom: 36px;
      }
    }
  }

  &__subtitle {
    margin-bottom: 16px;
  }
}
