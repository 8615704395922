@use 'sass:map';
@use 'sass:math';
@import '/src/assets/scss/shared';

.section {
  @include display-flex(column) {
    max-width: 92rem;
    min-width: 42rem;
  }

  &__title {
    color: map.get($color-list, 'green', 500);
    margin-bottom: 4.8rem;
  }

  &__footer {
    @include display-flex {
      align-items: center;
      justify-content: center;
      height: 10rem;
    }
  }
}

.profile_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.helpModal {
  margin-left: auto;
  margin-bottom: 4.8rem;
}

.form {
  fieldset {
    border: none;
    background-color: map.get($color-list, 'neutral', 100);
    border-radius: 2.4rem;
    padding: 3.2rem 9.6rem 5.2rem;
    margin-bottom: 2.4rem;
  }

  &__row {
    @include display-flex {
      justify-content: space-between;
    }
  }

  &__col {
    @include display-flex(column) {
      flex-basis: 0;
      flex-grow: 1;

      &:not(:last-child) {
        margin-right: 24px;
      }
    }

    &__camp_season {
      flex-direction: row;
    }
  }

  &__field {
    &__campaign {
      &__definition {
        height: 12.8rem;
      }
    }
  }
}

.ambassador_reward,
.prospect_reward {
  margin: 0px -2.4rem 1.6rem;
}

.button {
  &__back,
  &__next {
    margin-right: 1.6rem;
  }

  &__add_reward {
    &__wrapper {
      @include display-flex {
        justify-content: center;
        margin-top: 3.2rem;
      }
    }

    &__icon {
      margin-right: 0.8rem;
    }
  }

  &__camp_season {
    &__wrapper {
      margin-left: 1.2rem;

      label {
        visibility: hidden;
      }
    }
  }
}
