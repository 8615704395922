@use 'sass:map';
@import 'shared';
@import 'react-toastify/dist/ReactToastify.css';

html {
  font-size: 62.5%;
}

body {
  margin: 0px;
  overflow-x: hidden;
  @include body-text;

  &,
  * {
    box-sizing: border-box;
    transition-duration: $transition-duration;
  }
}

h1,
h2,
h3,
h4 {
  font-family: 'Rubik-Bold';
  font-weight: 700;
}

h1 {
  font-size: 3.6rem;
  line-height: 4.4rem;
}

h2 {
  font-size: 3.2rem;
  line-height: 4rem;
}

h3 {
  font-size: 2.8rem;
  line-height: 3.6rem;
}

h4 {
  font-size: 2.4rem;
  line-height: 3.2rem;
}

h5,
h6 {
  font-family: 'Rubik-Medium';
  font-weight: 500;
}

h5 {
  font-size: 2.1rem;
  line-height: 2.8rem;
  @include media-breakpoint-down('md') {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }
}

h6 {
  font-size: 1.8rem;
  line-height: 2.4rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  @include media-breakpoint-down('md') {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
}

.html-editor {
  .tox-tinymce {
    border: none;

    .tox-toolbar {
      &-overlord {
        background-color: transparent;
      }

      &__primary {
        background: transparent;
      }

      &__group {
        padding: 0px;
        margin-bottom: 8px;
        width: 100%;

        .tox-tbtn {
          width: 2.4rem;
          height: 2.4rem;
          margin: 0px 4px;
          cursor: pointer;
          background-repeat: no-repeat;
          background-position: center;
          border: 1px solid transparent;
          border-radius: 0.4rem;

          &:first-child {
            margin-left: 0px;
          }

          &--enabled {
            background: #fee2b9;
            border-color: #fcad37;

            svg path {
              fill: #976821;
            }
          }

          &:last-child {
            @include display-flex {
              align-items: center;
              justify-content: center;
              padding: 1.2rem 2.4rem;
              background-color: transparent;
              border: 1px solid map.get($color-list, 'orange', 400);
              border-radius: 2.4rem;
              padding: 1.2rem 1.6rem;
              height: 4.8rem;
              width: auto;
              margin-left: auto;
              margin-right: 0px;

              &,
              * {
                cursor: pointer;
              }
            }

            @include button-text {
              color: map.get($color-list, 'orange', 700);
              text-transform: uppercase;
            }

            @include media-breakpoint-up('xxl') {
              height: 4rem;
              padding: 0.8rem 1.6rem;
              @include button-text(2);
            }

            &:hover {
              background-color: map.get($color-list, 'orange', 100);
            }

            &:active,
            &:focus {
              background-color: map.get($color-list, 'orange', 200);
            }
          }

          .ct-image-svg {
            fill: #999999;
          }
        }
      }
    }

    .tox-edit-area {
      &__iframe {
        background: #ffffff;
        border: 1px solid map.get($color-list, 'neutral', 300);
        box-sizing: border-box;
        border-radius: 1.6rem;
        padding-right: 2px;
      }
    }
  }

  &.preview-hidden .tox-tinymce .tox-toolbar__group .tox-tbtn:last-child {
    display: none;
  }

  &--error .tox-tinymce .tox-edit-area__iframe {
    border-color: #d22827;
  }
}

button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
}

.react-datepicker {
  font-size: 1.3rem !important;
}

.react-datepicker__current-month {
  font-size: 1.5rem !important;
}

.react-datepicker__header {
  padding-top: 6px !important;
}

.react-datepicker__navigation {
  top: 13px !important;
}

.react-datepicker__day-name,
.react-datepicker__day {
  margin: 0.5rem !important;
}

.react-toggle {
  top: 5px !important;
  margin-left: 0.8rem;
}
.react-toggle-track {
  background-color: #fdca7d !important;
}
