@use "sass:map";
@import "/src/assets/scss/shared";

.primary_button {
  @include display-flex {
    align-items: center;
    justify-content: center;
    background-color: map.get($color-list, "orange", 400);
    border-radius: $border-radius;
    cursor: pointer;
    padding: 1.2rem 1.6rem;
    height: $button-height;
  }

  @include button-text(2) {
    color: map.get($color-list, "orange", 700);
    text-transform: uppercase;
  }

  @include media-breakpoint-up("xxl") {
    @include button-text(2);
    height: $button-height;
    padding: .8rem 1.6rem;
  }

  &__hovered {
    background-color: map.get($color-list, "orange", 300);
  }

  &__pressed {
    background-color: map.get($color-list, "orange", 200);
  }

  &__disabled {
    background-color: map.get($color-list, "neutral", 300);
    color: map.get($color-list, "neutral", 500);
    cursor: not-allowed;
  }
  &__description {
    font-family: "Rubik-Regular";
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 2.4rem;
    letter-spacing: 0.2px;
    color: #333333;
    margin-left: 9px;
    margin-top: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 12em;
  }
}
