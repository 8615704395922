
@mixin headline-text($type: 1) {
  font-family: "Rubik-Medium";
  font-weight: 500;

  @if $type == 1 {
    font-size: 3.6rem;
    line-height: 4.4rem;
  } @else if $type == 2 {
    font-size: 3.2rem;
    line-height: 4rem;
  } @else if $type == 3 {
    font-size: 2.8rem;
    line-height: 3.6rem;
  } @else if $type == 4 {
    font-size: 2.4rem;
    line-height: 3.2rem;
  } @else if $type == 5 {
    font-size: 2.1rem;
    line-height: 2.8rem;
  } @else if $type == 6 {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }

  @content;
}

@mixin subtitle-text($type: 1) {
  font-family: "Rubik-Medium";
  font-weight: 500;

  @if $type == 1 {
    font-size: 1.5rem;
    line-height: 2.4rem;
    letter-spacing: 0.25px;
  } @else if $type == 2 {
    font-size: 1.4rem;
    line-height: 2.4rem;
    letter-spacing: 0.2px;
    font-weight: 400;
  } @else if $type == 3 {
    font-size: 1.3rem;
    line-height: 2rem;
    letter-spacing: 0.4px;
  }

  @content;
}

@mixin body-text($type: 1) {
  font-family: "Rubik-Regular";
  font-weight: 400;

  @if $type == 1 {
    font-size: 1.5rem;
    line-height: 2.4rem;
    letter-spacing: 0.25px;
  } @else if $type == 2 {
    font-size: 1.4rem;
    line-height: 2.4rem;
    letter-spacing: 0.2px;
  } @else if $type == 3 {
    font-size: 1.3rem;
    line-height: 2.0rem;
    letter-spacing: 0.4px;
  }

  @content;
}

@mixin body-hyperlink-text($type: 1) {
  @include body-text($type);
  text-decoration: underline;

  @if $type == 1 {
    line-height: 2.8rem;
  }

  @content;
}

@mixin caption-text($type: 1) {
  font-family: "Rubik-Regular";
  font-weight: 400;
  letter-spacing: 0.4px;

  @if $type == 1 {
    font-size: 1.2rem;
    line-height: 1.6rem;
  } @else if $type == 2 {
    font-size: 1.1rem;
    line-height: 1.6rem;
  }

  @content;
}

@mixin caption-hyperlink-text($type: 1) {
  @include caption-text($type);
  text-decoration: underline;
  @content;
}

@mixin button-text($type: 1) {
  font-family: "Rubik-Medium";
  font-weight: 500;
  line-height: 2.4rem;
  letter-spacing: 1.5px;

  @if $type == 1 {
    font-size: 1.4rem;
  } @else if $type == 2 {
    font-size: 1.3rem;
  }

  @content;
}

@mixin tag-text {
  font-family: "Rubik-Medium";
  font-weight: 500;
  font-size: 1.0rem;
  line-height: 1.2rem;
  letter-spacing: 1.2px;
  @content;
}
