@use "sass:map";
@use "sass:math";
@import "/src/assets/scss/shared";

.page {
  padding: 24px;

  @include media-breakpoint-up("md") {
    padding: 80px 210px;
  }

  @include media-breakpoint-up("xxl") {
    padding: 32px 260px;
  }

  &__title {
    margin-bottom: 32px;

    @include media-breakpoint-up("md") {
      margin-bottom: 80px;

      h3 {
        text-align: center;
      }

      p {
        text-align: left;
      }
    }

    h3 {
      color: map.get($color-list, "green", 500);
      margin-bottom: 8px;
    }

    p {
      @include body-text(2);
      color: map.get($color-list, "neutral", 500);
    }

    @include media-breakpoint-up("xxl") {
      margin-bottom: 32px;

      h2 {
        color: map.get($color-list, "green", 500);

        span {
          margin-left: 24px;
          @include body-text(2) {
            color: map.get($color-list, "neutral", 500);
          }
        }
      }
    }
  }

  &__add_camp {
    @include display-flex(column) {
      position: relative;
      width: 450px;
      max-width: 100%;
      height: 248px;
      align-items: center;
      justify-content: center;
      border-radius: 24px;
      background-color: map.get($color-list, "neutral", 200);
    }

    &::before {
      content: "";
      position: absolute;
      border: 1px dashed map.get($color-list, "green", 400);
      border-radius: 16px;
      width: calc(100% - 16px);
      height: calc(100% - 16px);
    }

    &__icon {
      margin-bottom: 24px;
    }

    &__button {
      position: relative;
      width: 126px;
      @include button-text(2);

      &:disabled {
        color: lightgray;
      }
    }
  }
}
