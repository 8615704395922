@use 'sass:map';
@import '/src/assets/scss/shared';

@mixin draw-circle($color) {
  --deg: calc((3.6 * var(--value) - 90) * 1deg);
  background: linear-gradient(#fff, #fff) content-box,
    linear-gradient(
        var(--deg),
        map.get($color-list, $color, 200) 50%,
        transparent 0
      )
      0 / min(100%, (50 - var(--value)) * 100%),
    linear-gradient(
        var(--deg),
        transparent 50%,
        map.get($color-list, $color, 500) 0
      )
      0 / min(100%, (var(--value) - 50) * 100%),
    linear-gradient(
      to right,
      map.get($color-list, $color, 200) 50%,
      map.get($color-list, $color, 500) 0
    );
}

.campaign {
  @include display-flex {
    align-items: center;
    background: #ffffff;
    border-radius: 2.4rem;
    padding: 1.6rem;
    @include box-shadow;
  }

  &__stats {
    @include display-flex {
      align-items: center;
      justify-content: center;
      min-width: 4rem;
      min-height: 4rem;
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      padding: 0.4rem;
      margin-right: 1.6rem;
      @include caption-text(2);
    }

    @include media-breakpoint-up('md') {
      margin-right: 24px;
    }

    &--green {
      color: map.get($color-list, 'green', 500);
      @include draw-circle('green');
    }

    &--orange {
      color: map.get($color-list, 'orange', 700);
      @include draw-circle('orange');
    }

    &--red {
      color: map.get($color-list, 'red', 500);
      @include draw-circle('red');
    }
  }

  &__inner {
    @include display-flex(column) {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;

      &--INCOMPLETE {
        max-width: calc(100% - 6.4rem);
      }
    }

    &__top {
      @include display-flex;
      position: relative;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.8rem;
    }

    &__bottom {
      @include display-flex;
      align-items: center;
    }
  }

  &__name {
    @include subtitle-text;
    color: map.get($color-list, 'neutral', 800);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: calc(100% - 10rem);

    &_test {
      background-color: #fdca7d;
      margin-left: 10px;
      padding: 10px 5px 10px 5px;
      border-radius: 100px;
      color: #976821;
      font-weight: 100;
    }
  }

  &__type {
    @include body-text(2);
    color: map.get($color-list, 'orange', 700);
    margin-right: 1.6rem;
    text-transform: capitalize;

    &--verified {
      padding: 0.4rem 0.8rem;
      border-radius: 1.2rem;
      background-color: map.get($color-list, 'green', 200);
      @include caption-text;
      color: map.get($color-list, 'green', 600);
    }
  }

  &__created_at {
    @include body-text(2);
    color: map.get($color-list, 'neutral', 500);
    margin-left: auto;
  }

  &__action_button {
    border: none;
    background-color: transparent;
    padding: unset;
    cursor: pointer;

    &:hover svg {
      fill: map.get($color-list, 'green', 300);
    }

    &:active svg {
      fill: map.get($color-list, 'green', 400);
    }
  }
}

.action_menu {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 2.4rem;
  background-color: #fff;
  width: 24.5rem;
  max-height: 0px;
  overflow: hidden;
  @include box-shadow;

  &--opened {
    max-height: 30rem;
    z-index: 100;
  }

  &__item {
    @include display-flex {
      align-items: center;
      padding: 0.8rem 1.6rem;
      cursor: pointer;
      color: map.get($color-list, 'neutral', 800);
    }

    &:hover {
      background-color: map.get($color-list, 'orange', 200);
    }

    svg {
      margin-right: 1.6rem;
    }

    &__delete {
      color: map.get($color-list, 'red', 500);

      &:hover {
        background-color: map.get($color-list, 'red', 200);
      }
    }

    &__track {
      svg path {
        fill: map.get($color-list, 'orange', 200);
        stroke: map.get($color-list, 'orange', 700);
      }
    }
  }
}
