@use "sass:map";
@use "sass:math";
@import "/src/assets/scss/shared";

.header {
  @include display-flex(column) {
    margin-bottom: 24px;
  }

  .title {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: calc(100% - 55px);

    &--INCOMPLETE {
      color: map.get($color-list, "neutral", 500);
    }

    &--ACTIVE {
      color: map.get($color-list, "green", 500);
    }

    &--CLOSED {
      color: map.get($color-list, "red", 500);
    }

    &__wrapper {
      @include display-flex {
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
      }
    }
  }

  p {
    @include body-text(3) {
      color: map.get($color-list, "neutral", 400);
      margin-bottom: 8px;
    }

    &.type {
      @include body-text(2) {
        color: map.get($color-list, "green", 500);
      }
    }
  }
}

.main {
  @include display-flex(column) {
    margin-bottom: auto;
  }
}

.step__wrapper {
  @include display-flex(column);
}

.step {
  @include display-flex {
    cursor: pointer;
    align-items: center;
    padding: 16px 16px 16px 8px;
    border-radius: 8px;
  }

  @for $i from 1 through 6 {
    &[step-id="#{$i}"]::before {
      @include display-flex {
        content: "#{$i}";
        width: 24px;
        height: 24px;
        margin-right: 16px;
        border: 1px solid map.get($color-list, "neutral", 300);
        border-radius: 6px;
        align-items: center;
        justify-content: center;
      }

      @include subtitle-text(3) {
        color: map.get($color-list, "neutral", 600);
      }
    }
  }

  &--active {
    background-color: map.get($color-list, "neutral", 200);
  }

  &__created_at {
    @include caption-text;
    color: map.get($color-list, "neutral", 800);
  }

  &__text {
    @include subtitle-text(3);
    color: map.get($color-list, "neutral", 800);
  }
}

.icon {
  &__circle_check_mark {
    width: 16px;
    height: 16px;
    margin-right: 6px;

    &--hidden {
      display: none;
    }
  }

  &__chevron {
    margin-left: auto;
    transform: rotate(180deg);

    path {
      stroke: map.get($color-list, "neutral", 500);
    }

    &--opened {
      transform: rotate(0deg);
    }
  }
}

.sub_steps__wrapper {
  @include display-flex(column) {
    position: relative;
    padding-left: 40px;
    height: 0px;
    overflow: hidden;
  }

  &--opened {
    margin-top: 8px;
  }

  &::before {
    content: "";
    position: absolute;
    left: 20px;
    top: -4px;
    width: 1px;
    height: calc(100% - 46px);
    background-color: map.get($color-list, "neutral", 300);
  }
}

.sub_step {
  position: relative;
  color: map.get($color-list, "neutral", 600);
  padding: 12px 16px;
  border-radius: 8px;
  cursor: pointer;
  @include caption-text(2);

  &:not(:last-child)::before {
    content: "";
    position: absolute;
    left: -16px;
    top: 50%;
    width: 12px;
    height: 1px;
    background-color: map.get($color-list, "neutral", 300);
  }

  &:last-child {
    margin-bottom: 16px;

    &::before {
      content: "";
      position: absolute;
      left: -20px;
      top: 4px;
      width: 16px;
      height: 16px;
      border-bottom-left-radius: 100%;
      border: 1px solid transparent;
      border-bottom-color: map.get($color-list, "neutral", 300);
    }
  }

  &--active {
    background-color: map.get($color-list, "neutral", 200);
  }
}
