@use "sass:map";
@import "/src/assets/scss/shared";

.page {
  @include display-flex(column) {
    background-color: $color-white;
    min-height: 100vh;
    padding-left: 24px;
    padding-right: 24px;
  }

  @include media-breakpoint-up("md") {
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 56px;
  }

  @include media-breakpoint-up("xxl") {
    width: 560px;
    min-height: 660px;
    padding: 72px 118px;
    border-radius: 24px;
    margin: unset;
  }

  &__container {
    @include media-breakpoint-up("xxl") {
      @include display-flex {
        height: 100vh;
        min-height: 900px;
        background-color: map.get($color-list, "neutral", 200);
      }
    }
  }

  &__wrapper {
    @include media-breakpoint-up("xxl") {
      @include display-flex {
        align-items: center;
        justify-content: space-between;
        width: map.get($device-size, "xxl");
        padding: 112px 140px 128px;
        margin: auto;
      }
    }
  }

  &__illustration {
    @include media-breakpoint-up("xxl") {
      margin: 164px auto 96px 152px;
    }
  }
}

.logo {
  margin: 48px auto 32px;
  width: 110px;
  height: 56px;

  @include media-breakpoint-up("xxl") {
    margin-left: 0px;
    margin-top: 0px;
  }
}

.description {
  margin-bottom: 52px;

  @include body-text(2) {
    color: map.get($color-list, "neutral", 500);
    text-align: center;
  }

  @include media-breakpoint-up("xxl") {
    text-align: left;
  }
}

.input {
  margin-bottom: 72px;
}

.button {
  &s__container {
    margin-top: auto;
  }

  &__send {
    margin-bottom: 16px;
  }

  &__login {
    margin-bottom: 20px;
  }

  @include media-breakpoint-up("md") {
    &s__container {
      @include display-flex {
        justify-content: space-between;
        margin-top: 0px;
      }
    }

    &__send,
    &__login {
      width: 160px;
      margin-bottom: 0;
      padding: 12px 16px;
      height: 48px;
      @include media-breakpoint-up("xxl") {
        width: 155px;
      }
    }
  }
}
