@use 'sass:map';
@import '/src/assets/scss/shared';

.hide {
  display: none;
}
.page {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  // Uncomment when ready for notifications to return
  // grid-template-columns: repeat(4, 1fr);
  row-gap: $grid-gap;
  column-gap: $grid-gap;
  width: 90%;
  margin: auto;
  margin-top: $page-margin;

  &__right_column {
    grid-column: 4 / 5;
    grid-row: 1 / 5;
    min-width: 18rem;
  }

  &__large_panel {
    border-radius: $border-radius;
    background-color: map.get($color-list, 'neutral', 200);
    min-height: 18rem;
    grid-column: 1 / 4;

    &__title {
      margin: 1.6rem 2.4rem;
    }
  }
  .dashboard__overview {
    display: flex;
    justify-content: flex-end;
  }
}

.campaigns {
  &__headline {
    margin-bottom: 3rem;
  }
  &__count {
    margin-left: 1rem;
  }
  &__create {
    @include display-flex {
      align-items: center;
    }
  }
}

.help {
  margin-left: 10px;
}

.notifications {
  display: none;
  background-color: map.get($color-list, 'orange', 100);
  border-radius: $border-radius;

  &__title {
    display: flex;
    align-items: center;
    margin: 2.4rem 1.6rem;
  }

  &__date {
    margin: 1.6rem;
    @include caption-text(1);
    color: map.get($color-list, 'neutral', 600);
  }

  &__card {
    padding: 1.2rem;
    padding-left: 2.4rem;
    border-radius: $border-radius;
    // border: 1px solid #CCC;
    position: relative;
    overflow: hidden;
    margin: 1.6rem;
    background-color: white;
    @include box-shadow;

    &_type {
      @include subtitle-text(3);
    }

    &_icons {
      position: absolute;
      top: 1rem;
      right: 1rem;
      display: flex;
    }

    &_icon {
      height: 2.4rem;
      width: 2.4rem;
      cursor: pointer;
    }

    &_icon + &_icon {
      margin-left: 0.8rem;
    }

    &_data_main {
      @include body-text(2);
      color: map.get($color-list, 'neutral', 800);
    }

    &_data {
      @include body-text(3);
      color: map.get($color-list, 'neutral', 600);
    }
  }
}

.button {
  &__create_campaign {
    width: 15rem;
  }

  &__archive {
    margin-left: auto;
  }
}

.card {
  &__view_link {
    margin-left: 1.6rem;
  }
  &__data {
    display: flex;
    margin-top: 2rem;

    &_label {
      @include caption-text(1);
      color: map.get($color-list, 'neutral', 600);
    }

    &_item {
      display: flex;
      flex-direction: column;
    }

    &_item:not(:first-child) {
      margin-left: 2rem;
    }

    &_percent {
      position: absolute;
      top: 50%;
      right: 5rem;
      transform: translate(50%, 0);
    }
  }
}

.badge {
  margin-left: 1rem;
}

@mixin cell-padding {
  padding: 1rem 0.8rem;
}

@mixin cell-overline {
  border-top: 1px solid map.get($color-list, 'neutral', 400);
}

.table {
  display: grid;
  grid-template-columns: 2fr repeat(8, 1fr);
  @include cell-padding();
  column-gap: 0;

  &__header {
    @include cell-padding();
    padding-top: 0;
    @include caption-text(1);
    color: map.get($color-list, 'neutral', 600);
  }

  &__header_number {
    @include cell-padding();
    padding-top: 0;
    @include caption-text(1);
    color: map.get($color-list, 'neutral', 600);
    text-align: center;
  }

  &__data {
    @include cell-padding();
    @include cell-overline();
    @include body-text(3);
  }

  &__data_number {
    cursor: pointer;
    color: map.get($color-list, 'neutral', 800);
    text-align: center;
  }

  &__data_hyperlink {
    @include cell-overline();
    @include body-hyperlink-text(3);
    color: map.get($color-list, 'orange', 700);
    cursor: pointer;
    border-top: none;
  }

  &__test_campaign {
    background-color: #fdca7d;
    margin-left: 10px;
    padding: 10px 5px 10px 5px;
    border-radius: 100px;
    color: #976821;
    font-weight: 100;
  }
}
