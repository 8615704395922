@use "sass:map";
@import "/src/assets/scss/shared";

.modal {
  width: 800px;
  padding: 48px 92px 64px;

  * {
    transition-duration: unset;
  }

  &__content {
    @include display-flex(column) {
      height: 100%;
      align-items: center;
    }
  }
}

.title {
  color: map.get($color-list, "neutral", 800);
  margin-bottom: 64px;
  text-align: center;
}

.close {
  position: absolute;
  background-color: transparent;
  padding: 0px;
  border: none;
  cursor: pointer;
  top: 16px;
  right: 16px;
}

.buttons {
  @include display-flex {
    align-items: center;
    justify-content: center;
  }
}

.button {
  &__cancel {
    width: 150px;
    margin-right: 24px;

    @include media-breakpoint-up("xxl") {
      width: 110px;
    }
  }

  &__save {
    width: 150px;

    @include media-breakpoint-up("xxl") {
      width: 110px;
    }
  }
}
