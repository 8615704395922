@use "sass:map";
@import "/src/assets/scss/shared";

.modal {
  @include media-breakpoint-down("sm") {
    width: 100%;
    height: 100%;
    border-radius: 0px;
    padding: 164px 24px 24px;
  }

  * {
    transition-duration: unset;
  }

  &__content {
    @include display-flex(column) {
      height: 100%;
      align-items: center;
    }
  }
}

.seasons {
  width: 100%;
  margin-bottom: 24px;
}

.title {
  color: map.get($color-list, "neutral", 800);
  margin-bottom: 48px;
  text-align: center;
}

.close {
  position: absolute;
  background-color: transparent;
  padding: 0px;
  border: none;
  cursor: pointer;
  top: 16px;
  right: 16px;
}

.button {
  &s {
    @include display-flex {
      align-items: center;
      justify-content: center;
      margin-top: auto;
    }
  }

  &__cancel {
    width: 150px;
    margin-right: 24px;

    @include media-breakpoint-up("xxl") {
      width: 110px;
    }
  }

  &__save {
    width: 150px;

    @include media-breakpoint-up("xxl") {
      width: 110px;
    }
  }
}
