// @import "fonts";
@import "grid";
@import "colors";
@import "texts";
@import "variables";
@import "typography";

@mixin display-flex($direction: row) {
  display: flex;
  flex-direction: $direction;
  @content;
}

@mixin box-shadow {
  box-shadow: 0px 1px 8px -2px rgba(80, 85, 92, 0.03), 0px 5px 10px 0.2px rgba(80, 85, 92, 0.03),
    0px 15px 20px 0.2px rgba(80, 85, 92, 0.05);
}
