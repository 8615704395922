@use "sass:map";
@import "/src/assets/scss/shared";

.page {
  @include display-flex {
    position: relative;
  }
}

.left {
  @include display-flex(column) {
    margin-right: 24px;
    background-color: map.get($color-list, "neutral", 100);
    // height: calc(100vh - 80px);
    padding: 40px 24px 24px;
    min-width: 355px;
    max-width: 355px;
  }

  &--fixed {
    position: fixed;
    top: 0px;
    bottom: 0px;
    height: 100vh;
  }
}

.right {
  position: relative;
  transition-duration: unset;
  padding-top: 24px;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 1038px;

  &--fixed {
    margin-left: 380px;
  }
}
