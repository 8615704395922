@keyframes rotating-lines {
  100% {
    transform: rotate(360deg);
  }
}

.loading {
  animation: rotating-lines 0.75s steps(12) 0s infinite;

  polyline {
    stroke-width: 5px;
    stroke-linecap: round;

    &:nth-child(12n + 1) {
      stroke-opacity: 0.17;
    }

    &:nth-child(12n + 2) {
      stroke-opacity: 0.25;
    }

    &:nth-child(12n + 3) {
      stroke-opacity: 0.33;
    }

    &:nth-child(12n + 4) {
      stroke-opacity: 0.42;
    }

    &:nth-child(12n + 5) {
      stroke-opacity: 0.5;
    }

    &:nth-child(12n + 6) {
      stroke-opacity: 0.58;
    }

    &:nth-child(12n + 7) {
      stroke-opacity: 0.66;
    }

    &:nth-child(12n + 8) {
      stroke-opacity: 0.75;
    }

    &:nth-child(12n + 9) {
      stroke-opacity: 0.83;
    }

    &:nth-child(12n + 11) {
      stroke-opacity: 0.92;
    }

    &:nth-child(12n) {
      stroke-opacity: 0.08;
    }
  }
}
